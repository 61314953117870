import { PLAYER_KEY_MAPPING, VIDEO_PLAYER_ID } from '@components/VideoPlayer/constants';
import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import usePlayerContext from '@components/VideoPlayer/store/WithContextProvider';
import { isFullScreenApiAvailable } from '@components/VideoPlayer/Utils/utils';
import { useDeviceInfo } from '@hooks/react-use';
import { useAdsStore } from '@stores/adsStore';
import { useStreamStore } from '@stores/streamStore';
import { increaseCount } from '@stores/usePlayerEvents';
import React, { useEffect, useRef } from 'react';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const {
  toggleTheatreMode,
  setIsFullScreen,
  setHideCommentSection,
  setIsPlaying
} = useStreamStore.getState();
const {
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
const {
  setIsPortalOpen,
  setVolume,
  setIsMuted
} = usePlayerStore.getState();
const KeyboardShortcuts = () => {
  const isPlaying = useStreamStore(state => state.isPlaying);
  const isBuffering = useStreamStore(state => state.isBuffering);
  const isLoading = usePlayerStore(state => state.isLoading);
  const {
    device_isMobile
  } = useDeviceInfo();
  const {
    onSeekToThisPosition
  } = usePlayerContext();
  const SeekToRef = useRef(onSeekToThisPosition);
  SeekToRef.current = onSeekToThisPosition;
  const HandleClick = (keyPressed: string, e: React.KeyboardEvent) => {
    const {
      showInStreamAdControl,
      ad,
      updateVolume,
      volume_state,
      setAdMuted,
      adMuted
    } = useAdsStore.getState();
    const stream = usePlayerStore.getState().playerProps.stream;
    const isShowAdControls = !!ad?.id && showInStreamAdControl;
    const {
      isFullScreen,
      isPlaying,
      theatreMode,
      hideCommentSection
    } = useStreamStore.getState();
    const {
      isPortalOpen,
      isMuted,
      volume
    } = usePlayerStore.getState();
    if (isShowAdControls) {
      // AD control shortcuts
      switch (keyPressed) {
        case PLAYER_KEY_MAPPING.full_screen:
          const isApiPresent = isFullScreenApiAvailable();
          if (!isApiPresent) return;
          resetControlSectionVisiblity();
          setIsFullScreen(!isFullScreen);
          return;
        case PLAYER_KEY_MAPPING.theatre_mode:
          resetControlSectionVisiblity();
          toggleTheatreMode(!theatreMode);
          return;
        case PLAYER_KEY_MAPPING.mute_unmute:
          resetControlSectionVisiblity();
          setAdMuted(!adMuted);
          setIsMuted(!adMuted); //  update player with same value
          return;
        case PLAYER_KEY_MAPPING.volume_up:
          resetControlSectionVisiblity();
          e.preventDefault();
          setVolume(volume_state + 0.1); // update player with same value
          updateVolume(volume_state + 0.1);
          return;
        case PLAYER_KEY_MAPPING.volume_down:
          resetControlSectionVisiblity();
          e.preventDefault();
          setVolume(volume_state - 0.1); // update player with same value
          updateVolume(volume_state - 0.1);
          return;
        case PLAYER_KEY_MAPPING.chat_view:
          resetControlSectionVisiblity();
          const value = !hideCommentSection;
          setHideCommentSection(value);
          if (value) {
            increaseCount(stream?.uid || '', 'chat_section_collapsed_count');
          } else {
            increaseCount(stream?.uid || '', 'chat_section_reopen_count');
          }
          return;
      }
      if (PLAYER_KEY_MAPPING.play_pause.includes(keyPressed)) {
        e.preventDefault();
        resetControlSectionVisiblity();
        const onPlayPauseClick = () => {
          if (!window.VMAXSDK) return;
          const {
            adPlaying
          } = useAdsStore.getState();
          if (adPlaying) {
            window.VMAXSDK.pauseAd('a1');
          } else {
            window.VMAXSDK.resumeAd('a1');
          }
        };
        onPlayPauseClick();
        return;
      }
    } else {
      // VideoPlayer control shortcuts

      switch (keyPressed) {
        case PLAYER_KEY_MAPPING.full_screen:
          const isApiPresent = isFullScreenApiAvailable();
          if (!isApiPresent) return;
          resetControlSectionVisiblity();
          setIsFullScreen(!isFullScreen);
          return;
        case PLAYER_KEY_MAPPING.theatre_mode:
          resetControlSectionVisiblity();
          toggleTheatreMode(!theatreMode);
          return;
        case PLAYER_KEY_MAPPING.mini_player:
          resetControlSectionVisiblity();
          setIsPortalOpen(!isPortalOpen, true);
          return;
        case PLAYER_KEY_MAPPING.mute_unmute:
          increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
          resetControlSectionVisiblity();
          setIsMuted(!isMuted);
          return;
        case PLAYER_KEY_MAPPING.volume_up:
          {
            const p_muted = usePlayerStore.getState().isMuted;
            const n_volume = volume + 0.1;
            if (p_muted && n_volume > 0) {
              increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            } else if (!p_muted && n_volume === 0) {
              increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            }
            resetControlSectionVisiblity();
            e.preventDefault();
            setVolume(n_volume);
          }
          return;
        case PLAYER_KEY_MAPPING.volume_down:
          {
            const p_muted = usePlayerStore.getState().isMuted;
            const n_volume = volume - 0.1;
            if (p_muted && n_volume > 0) {
              increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            } else if (!p_muted && n_volume === 0) {
              increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            }
            resetControlSectionVisiblity();
            e.preventDefault();
            setVolume(n_volume);
          }
          return;
        case PLAYER_KEY_MAPPING.forward_seek:
          resetControlSectionVisiblity();
          increaseCount(stream?.uid || '', 'player_keyboard_seek_count');
          SeekToRef.current({
            delta: 10
          });
          return;
        case PLAYER_KEY_MAPPING.backward_seek:
          resetControlSectionVisiblity();
          increaseCount(stream?.uid || '', 'player_keyboard_seek_count');
          SeekToRef.current({
            delta: -10
          });
          return;
        case PLAYER_KEY_MAPPING.chat_view:
          resetControlSectionVisiblity();
          const value = !hideCommentSection;
          setHideCommentSection(value);
          if (value) {
            increaseCount(stream?.uid || '', 'chat_section_collapsed_count');
          } else {
            increaseCount(stream?.uid || '', 'chat_section_reopen_count');
          }
          return;
      }
      if (PLAYER_KEY_MAPPING.play_pause.includes(keyPressed)) {
        e.preventDefault();
        resetControlSectionVisiblity();
        increaseCount(stream?.uid || '', 'player_play_pause_toggle_count', 1);
        setIsPlaying(!isPlaying);
        return;
      }
    }
  };
  const HandleClickRef = useRef(HandleClick);
  HandleClickRef.current = HandleClick;
  useEffect(() => {
    const onKeyPressed: any = (e: React.KeyboardEvent) => {
      const element = (e.target as HTMLElement);
      if (element && (element.tagName.toLowerCase() === 'input' || element.tagName.toLowerCase() === 'textarea') || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
        return;
      }
      if (e.key) {
        HandleClickRef.current((e.key || '').toLowerCase(), e);
      }
    };
    document.addEventListener('keydown', onKeyPressed);
    return () => {
      document.removeEventListener('keydown', onKeyPressed);
    };
  }, []);

  // ?Double click event for mobile

  const playerTabLocation = useRef(0);
  const playerTab = useRef<null | NodeJS.Timeout>(null);
  useEffect(() => {
    const doubleTabCallback = (event: any) => {
      const {
        showInStreamAdControl,
        ad
      } = useAdsStore.getState();
      const stream = usePlayerStore.getState().playerProps.stream;
      const isShowAdControls = !!ad?.id && showInStreamAdControl;
      const videoContainer = document.getElementById(VIDEO_PLAYER_ID);
      if (!videoContainer || isShowAdControls) {
        return;
      }
      increaseCount(stream?.uid || '', 'player_keyboard_seek_count');
      const {
        left,
        width
      } = videoContainer.getBoundingClientRect();
      const touchLocation = event.changedTouches[0].clientX - left;
      if (playerTab.current) {
        const PositionDiff = Math.abs(touchLocation - playerTabLocation.current);
        if (PositionDiff < 60) {
          const HalfWidth = +(width / 2).toFixed(0);
          const isBothBackwardSeek = touchLocation < HalfWidth && playerTabLocation.current < HalfWidth;
          const isBothForwardSeek = touchLocation > HalfWidth && playerTabLocation.current > HalfWidth;
          if (isBothBackwardSeek) {
            resetControlSectionVisiblity();
            SeekToRef.current({
              delta: -10
            });
          } else if (isBothForwardSeek) {
            resetControlSectionVisiblity();
            SeekToRef.current({
              delta: 10
            });
          }
        }
      } else {
        playerTabLocation.current = touchLocation;
        playerTab.current = setTimeout(() => {
          playerTab.current = null;
        }, 500);
      }
    };
    const VideoContainer = document.getElementById(VIDEO_PLAYER_ID);
    if (!VideoContainer) return;
    VideoContainer.addEventListener('touchend', doubleTabCallback);
    return () => {
      VideoContainer.removeEventListener('touchend', doubleTabCallback);
    };
  }, [isPlaying, isBuffering, isLoading]);

  // Fullscreen toggle

  useEffect(() => {
    const VideoContainer = document.getElementById(VIDEO_PLAYER_ID);
    const {
      showInStreamAdControl,
      ad
    } = useAdsStore.getState();
    const isShowAdControls = !!ad?.id && showInStreamAdControl;
    const isApiPresent = isFullScreenApiAvailable();
    if (!isApiPresent) return;
    if (!VideoContainer || isShowAdControls || device_isMobile) {
      return;
    }
    const doubleClickCallBack = () => {
      const {
        isFullScreen
      } = useStreamStore.getState();
      resetControlSectionVisiblity();
      setIsFullScreen(!isFullScreen);
    };
    VideoContainer.addEventListener('dblclick', doubleClickCallBack);
    return () => {
      VideoContainer.removeEventListener('dblclick', doubleClickCallBack);
    };
  }, [device_isMobile, isPlaying, isBuffering, isLoading]);
  return _jsx(_Fragment, {});
};
export default KeyboardShortcuts;