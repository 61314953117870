// https://github.com/garmeeh/next-seo

const defaultSeo = {
  title: 'Loco | Free Online Gaming, Live-streaming & Esports Platform',
  description: 'Watch top esports tournaments and gaming creators playing and live streaming all your favorite games like Free Fire, PUBG, BGMI, Valorant, GTA V and many more!',
  openGraph: {
    site_name: 'Loco',
    type: 'website',
    locale: 'en-Us',
    description: 'Watch top esports tournaments and gaming creators playing and live streaming all your favorite games like Free Fire, PUBG, BGMI, Valorant, GTA V and many more!',
    url: 'https://loco.gg',
    canonical: 'https://loco.gg',
    images: [{
      url: 'https://loco.gg/logo_with_bg.png',
      width: 800,
      height: 600,
      alt: 'Video thumbnail'
    }]
  },
  twitter: {
    handle: '@GetLocoNow',
    site: '@GetLocoNow',
    cardType: 'summary_large_image'
  }
};
export default defaultSeo;