import { positions } from 'react-alert';

//@ts-ignore
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AlertTemplate = ({
  style,
  options,
  message
}) => _jsxs("div", {
  style: {
    background: 'white',
    zIndex: 1000,
    padding: '8px 12px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...style
  },
  children: [options.type === 'info' && ' ! ', options.type === 'success', options.type === 'error' && ' :( ', _jsx("div", {
    style: {
      marginLeft: '2px',
      fontSize: '14px',
      fontWeight: 'normal',
      display: 'inline-block'
    },
    children: message
  })]
});
export const Alertoptions = {
  timeout: 3000,
  position: positions.TOP_CENTER,
  offset: '80px',
  containerStyle: {
    zIndex: 100
  }
};