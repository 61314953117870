function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useDeviceInfo } from '@hooks/react-use';
import { useStreamStore } from '@stores/streamStore';
import React from 'react';
import { useControlDisplayStore } from '../store/useControlDisplayStore';
import { usePlayerStore } from '../store/usePlayerStore';
import { formatTime } from '../Utils/utils';
import StreamEnded from './Common/StreamEnded';
import FullScreenElement from './ControlSheetSection/FullScreenElement';
import MiniPlayerToogle from './ControlSheetSection/MiniPlayerToggle';
import MoveMiniPlayer from './ControlSheetSection/MoveMiniPlayer';
import SeekbarControls from './ControlSheetSection/SeekbarControls';
import TheatreMode from './ControlSheetSection/TheatreMode';
import PlayerSettingComp from './PlayerSettingComp';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsHoverOnContainer,
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
const {
  setIsPortalOpen
} = usePlayerStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1vro5xw",
  styles: "position:absolute;z-index:10;display:flex;height:100%;width:100%;align-items:center;justify-content:center;padding-left:1rem;padding-right:1rem;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms"
} : {
  name: "11g78me-StreamEndedOutside",
  styles: "position:absolute;z-index:10;display:flex;height:100%;width:100%;align-items:center;justify-content:center;padding-left:1rem;padding-right:1rem;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "nrwq21",
  styles: "position:absolute;z-index:-1;height:100%;width:100%;object-fit:cover"
} : {
  name: "2k5pp3-StreamEndedOutside",
  styles: "position:absolute;z-index:-1;height:100%;width:100%;object-fit:cover;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "yoovvv",
  styles: "position:absolute;left:0.75rem;right:0.75rem;top:0.75rem;z-index:100;display:flex;align-items:center;justify-content:space-between;border-radius:0.375rem"
} : {
  name: "160os51-StreamEndedOutside",
  styles: "position:absolute;left:0.75rem;right:0.75rem;top:0.75rem;z-index:100;display:flex;align-items:center;justify-content:space-between;border-radius:0.375rem;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1wnowod",
  styles: "display:flex;align-items:center;justify-content:center"
} : {
  name: "us8fqc-StreamEndedOutside",
  styles: "display:flex;align-items:center;justify-content:center;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "dopfyw",
  styles: "padding-left:0.5rem;padding-bottom:0.25rem;padding-top:0.125rem;padding-right:0.25rem"
} : {
  name: "16fn66y-StreamEndedOutside",
  styles: "padding-left:0.5rem;padding-bottom:0.25rem;padding-top:0.125rem;padding-right:0.25rem;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "18icuxn",
  styles: "--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "wnmjhj-StreamEndedOutside",
  styles: "--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "qqykjf",
  styles: "margin-right:0.25rem"
} : {
  name: "11f85lm-StreamEndedOutside",
  styles: "margin-right:0.25rem;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1y2mh23",
  styles: "margin-left:0.25rem"
} : {
  name: "wy7mva-StreamEndedOutside",
  styles: "margin-left:0.25rem;label:StreamEndedOutside;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const StreamEndedOutside = () => {
  const {
    screen_isDesktop
  } = useDeviceInfo();
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);
  const {
    theatreMode,
    isFullScreen
  } = useStreamStore(state => ({
    isFullScreen: state.isFullScreen,
    theatreMode: state.theatreMode
  }));
  const playerDuration = usePlayerStore(state => state.playerDuration);
  const playerPosition = usePlayerStore(state => state.playerPosition);
  const stream = useStreamStore(state => state.stream);
  const isChannelLive = stream?.status === 20;
  const thumbnail_url_small = stream?.thumbnail_url_small || '';
  const isVisible = true;
  return _jsx("div", {
    className: "player-section",
    children: _jsxs("div", {
      className: "video-container",
      onMouseMove: () => {
        if (!theatreMode && !isFullScreen) {
          if (screen_isDesktop) {
            setIsHoverOnContainer(true);
          } else {
            resetControlSectionVisiblity();
          }
        } else {
          // For theatre mode & full screen mode
          resetControlSectionVisiblity();
        }
      },
      onMouseLeave: () => setIsHoverOnContainer(false),
      children: [_jsxs("div", {
        css: _ref,
        style: {
          opacity: isVisible ? 1 : 0
        },
        children: [thumbnail_url_small ? _jsx("img", {
          css: _ref2,
          src: thumbnail_url_small,
          alt: "Stream has Ended"
        }) : _jsx(_Fragment, {}), _jsx(StreamEnded, {})]
      }), isPortalOpen ? _jsxs(_Fragment, {
        children: [_jsx(MoveMiniPlayer, {}), _jsxs("div", {
          css: _ref3,
          children: [_jsx(MiniPlayerToogle, {}), _jsx("div", {
            css: _ref4,
            children: _jsx("button", {
              className: "mini-player-exit",
              onPointerDown: () => {
                setIsPortalOpen(false, true);
              },
              css: _ref5,
              children: _jsx("svg", {
                width: "14",
                height: "14",
                viewBox: "0 0 14 14",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
                children: _jsx("path", {
                  d: "M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z",
                  fill: "white"
                })
              })
            })
          })]
        })]
      }) : _jsxs("div", {
        className: "player-controls-container",
        style: {
          transform: isVisible ? 'translateY(0)' : 'translateY(50%)',
          opacity: !!isVisible ? 1 : 0,
          visibility: !!isVisible ? 'visible' : 'hidden'
        },
        children: [_jsxs("div", {
          className: "player-controls-wrapper player-setting-wrapper",
          children: [_jsxs("div", {
            className: "player-controls-btn-container player-main-control",
            children: [_jsx("button", {
              onClick: () => {
                useStreamStore.getState().updatePlaybackUrl(true);
              },
              children: _jsx("svg", {
                width: "36",
                height: "36",
                viewBox: "0 0 36 36",
                children: _jsx("path", {
                  fill: "white",
                  d: "M 18,11 V 7 l -5,5 5,5 v -4 c 3.3,0 6,2.7 6,6 0,3.3 -2.7,6 -6,6 -3.3,0 -6,-2.7 -6,-6 h -2 c 0,4.4 3.6,8 8,8 4.4,0 8,-3.6 8,-8 0,-4.4 -3.6,-8 -8,-8 z"
                })
              })
            }), _jsxs("div", {
              css: _ref6,
              children: [_jsx("p", {
                className: "time-since-live player-position",
                css: _ref7,
                children: formatTime(playerPosition)
              }), isChannelLive ? _jsx(_Fragment, {}) : _jsxs(_Fragment, {
                children: ["/", _jsx("p", {
                  className: "time-since-live player-duration",
                  css: _ref8,
                  children: formatTime(playerDuration)
                })]
              })]
            })]
          }), _jsxs("div", {
            className: "player-controls-btn-container player-setting",
            children: [_jsx(PlayerSettingComp, {
              disabled: true
            }), _jsx(MiniPlayerToogle, {
              disabled: true
            }), _jsx(TheatreMode, {}), _jsx(FullScreenElement, {})]
          })]
        }), _jsx(SeekbarControls, {
          disabled: true
        })]
      })]
    })
  });
};
export default StreamEndedOutside;