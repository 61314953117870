import 'react-circular-progressbar/dist/styles.css';
import '@styles/global.css';
import '@styles/newPlayer.css';
import '@modules/analytics';
import '@components/VideoPlayer/styles/player.css';
import '@components/VideoPlayer/styles/controls.css';
import 'ui/styles.css';
import GlobalStyles from '@base/GlobalStyles';
import { GoogleOAuthProvider } from '@components/GoogleLogin';
import MiniPlayerEntry from '@components/VideoPlayer/MiniPlayerEntry';
import PlayerExit from '@components/VideoPlayer/PlayerExit';
import { FEEDCOMPONENTTYPE, HomeFeedResultInterface, SidebarFeedInterface } from '@customTypes/index';
import { GetLocoNowUrl } from '@data/getloconowUrl';
import { cache } from '@emotion/css';
import { CacheProvider } from '@emotion/react';
import { getDataMapping } from '@functions/api';
import { getUserPermission } from '@functions/permission';
import { FeedTypeENUM, getFeedData, getPlayBackUrl, getSidebarFeedData, SidebarFeedTypeENUM } from '@functions/service';
import { useDeviceInfo } from '@hooks/react-use';
import useDelayEffect from '@hooks/useDelayEffect';
import useGTM from '@hooks/useGtm';
import useScript from '@hooks/useScript';
import BaseLayout from '@layouts/core/Layout';
import { PageWrapper } from '@layouts/core/PageWrapper';
import setUpAds from '@modules/ads/setUpAds';
import { closeBannerAdFunc } from '@modules/ads/setUpBannerAds';
import { Amplitude } from '@modules/analytics';
import { GoogleTagManager } from '@next/third-parties/google';
import { useAdsStore } from '@stores/adsStore';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { useCarouselPlayerStore } from '@stores/carouselPlayerStore';
import { useProfileStore } from '@stores/profileStore';
import { useSidebarPlayerStore } from '@stores/sidebarPlayerStore';
import { useSidebarStore } from '@stores/sideBarStore';
import { useStreamStore } from '@stores/streamStore';
import { checkIfBot } from '@utils/checkIfBot';
import { getDeviceInfo } from '@utils/deviceInfo';
import { GA_TRACKING_ID } from '@utils/gtm';
import { isProduction } from '@utils/platform';
import unregister from '@utils/scripts/unregister';
import { AppPropsType } from 'next/dist/shared/lib/utils';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Provider } from 'react-alert';
import { useInterval } from 'react-use';
import useHandleLogin from 'src/application/useHandleLogin';
import useSideBar from 'src/application/useSideBar';
import * as workerTimers from 'worker-timers';
import { Alertoptions, AlertTemplate } from '../application/alert/template';
import SEO from '../utils/next-seo.config';

// import useScript from '@hooks/useScript'
// import setUpAds from '@modules/ads/setUpAds'
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const LazyCss = dynamic(() => import('src/application/LazyCss'), {
  ssr: false
});
const Login = dynamic(() => import('src/application/Login'), {
  ssr: false
});
declare global {
  interface Window {
    IVSPlayer: any;
  }
}
export default function MyApp({
  Component,
  pageProps
}: AppPropsType): JSX.Element {
  const [anyStreamId, setAnyStreamId] = useState('');
  const [isEmbed, setIsEmbed] = useState(false);
  const [isGetLocoNowUrl, setGetLocoNowUrl] = useState(false);
  const me = useAuthStore(state => state.me);
  const isUserVipProperty = useProfileStore(state => !!state?.isUserVip);
  const user_uid = useAuthStore(state => state.me?.user_uid);
  const accessToken = useAuthStore(state => state.accessToken);
  const loginBonus = useAuthStore(state => state.loginBonus);
  const sidebarPlayer = useSidebarPlayerStore(state => state.sidebarPlayer);
  const currentStreamId = useStreamStore(state => state.stream?.uid);
  const setSidebarPlayer = useSidebarPlayerStore(state => state.setSidebarPlayer);
  const carouselPlayer = useCarouselPlayerStore(state => state.carouselPlayer);
  const setCarouselPlayer = useCarouselPlayerStore(state => state.setCarouselPlayer);
  const setSideBarFeedData = useSidebarStore(state => state.setSideBarFeedData);
  const homeFeedData = useAppStore(state => state.homeFeedData);
  const setHomeFeedData = useAppStore(state => state.setHomeFeedData);
  useDeviceInfo.DeviceInfoInitialization();
  const {
    screen_isDesktop
  } = useDeviceInfo();
  const route = useRouter();
  const RouteToSidebarFeedTypeMapper: {
    [id: string]: string;
  } = {
    categories: SidebarFeedTypeENUM.CATEGORIES,
    streamers: SidebarFeedTypeENUM.STREAMER,
    stream: SidebarFeedTypeENUM.STREAM
  };
  const RoutePathHavingSidebarFeedTypeList = ['categories', 'streamers', 'stream'];
  useHandleLogin();
  useSideBar();
  useGTM();
  useEffect(() => {
    const setMounted = useAppStore.getState().setMounted;
    setMounted(true);
    const updateClientSideMapping = async () => {
      const result = await getDataMapping();
      const streamersMapping = result?.streamers || [];
      const categoryMapping = result?.categories || [];
      useAppStore.setState({
        categoryMapping: categoryMapping,
        streamerMapping: streamersMapping // require('@data/streamerMapping.server'), // This is somehow using the space
      });
    };

    const timerRef = workerTimers.setTimeout(() => {
      updateClientSideMapping();
    }, 100);
    return () => {
      workerTimers.clearTimeout(timerRef);
      setMounted(false);
    };
  }, []);
  useEffect(() => {
    const timerRef = workerTimers.setTimeout(async () => {
      const deviceInfo = await getDeviceInfo();
      useAppStore.getState().setDeviceInfo(deviceInfo);
    }, 250);
    checkIfBot();
    import('@base/NextNprogress/NextNprogress');
    unregister();
    return () => {
      workerTimers.clearTimeout(timerRef);
    };
  }, []);

  // SET/ROMOVE user based on LOGIN/LOGOUT amplitude event
  useEffect(() => {
    checkIfBot();
    if (me?.user_uid && accessToken && (me?.social_link_info?.gmail || me?.phone)) {
      Amplitude.updateAmplitudeUser(me);
    } else {
      Amplitude.resetAmplitudeUser();
    }
  }, [user_uid, accessToken, me]);
  useDelayEffect(() => {
    if (!window?.IVSPlayer) return;
    if (!sidebarPlayer) {
      const IVSPlayer = window?.IVSPlayer;
      const __sidebarPlayer = IVSPlayer.create();
      __sidebarPlayer?.setRequestCredentials('include');
      setSidebarPlayer(__sidebarPlayer);
    }
    if (!carouselPlayer) {
      const IVSPlayer = window?.IVSPlayer;
      const __carousalPlayer = IVSPlayer.create();
      __carousalPlayer?.setRequestCredentials('include');
      setCarouselPlayer(__carousalPlayer);
    }
  }, [accessToken]);
  useEffect(() => {
    if (accessToken) {
      ;
      (async () => {
        try {
          const currentPath = route.pathname.split('/')[1];
          const feedType = RoutePathHavingSidebarFeedTypeList.includes(currentPath) ? RouteToSidebarFeedTypeMapper[currentPath] : SidebarFeedTypeENUM.HOME;
          const sidebarFeed: SidebarFeedInterface = await getSidebarFeedData({
            limit: '30',
            offset: '0',
            type: feedType,
            token: accessToken
          });
          if (sidebarFeed?.results?.length) {
            setSideBarFeedData(sidebarFeed);
          } else {
            setSideBarFeedData({
              results: []
            });
          }
        } catch (err) {
          setSideBarFeedData({
            results: []
          });
        }
      })();
    }
  }, [accessToken, route.pathname]);
  async function fetchSideBarFeed(type: string) {
    if (!accessToken) return;
    try {
      const sidebarFeed: SidebarFeedInterface = await getSidebarFeedData({
        limit: '30',
        offset: '0',
        type: type,
        token: accessToken
      });
      if (sidebarFeed?.results?.length) {
        setSideBarFeedData(sidebarFeed);
      } else {
        setSideBarFeedData({
          results: []
        });
      }
    } catch (err) {
      setSideBarFeedData({
        results: []
      });
    }
  }
  useInterval(() => {
    const currentPath = route.pathname.split('/')[1];
    const feedType = RoutePathHavingSidebarFeedTypeList.includes(currentPath) ? RouteToSidebarFeedTypeMapper[currentPath] : SidebarFeedTypeENUM.HOME;
    fetchSideBarFeed(feedType);
  }, screen_isDesktop ? 60000 : null);
  const MakeApiCallToSetCookieToken = async (streamId: string) => {
    await getPlayBackUrl(streamId);
  };
  useDelayEffect(() => {
    const StreamIdForCookie = currentStreamId || anyStreamId;
    if (StreamIdForCookie) {
      if (!route.pathname.startsWith('/stream/') && !route.pathname.startsWith('/streamers/')) {
        // No need to make instant api call, as PlayerContainer will make same call
        MakeApiCallToSetCookieToken(StreamIdForCookie);
      }
      const intervelTimer = workerTimers.setInterval(() => {
        MakeApiCallToSetCookieToken(StreamIdForCookie);
      }, 30 * 60 * 1000); // 30 minutes
      return () => {
        workerTimers.clearInterval(intervelTimer);
      };
    }
  }, [anyStreamId, currentStreamId], 250);
  useEffect(() => {
    if (homeFeedData || !accessToken) return;
    (async () => {
      const homeFeed = await getFeedData({
        limit: '30',
        offset: '0',
        type: FeedTypeENUM.HOME,
        token: accessToken
      });
      if (!homeFeed.results.length) return;
      setHomeFeedData(homeFeed);

      // Fetching Carousal Data, id:4 & name:FULL_WIDTH_VOD_OR_LIVE
      const videoStream = homeFeed?.results.find((elem: HomeFeedResultInterface) => {
        return elem.content[0].component.name === FEEDCOMPONENTTYPE.FULL_WIDTH_VOD_OR_LIVE;
      });
      const uid = videoStream?.content[0]?.component?.data?.stream_uid?.data;
      setAnyStreamId(uid || '');
    })();
  }, [accessToken]);
  useEffect(() => {
    if (!me?.user_uid) {
      useAdsStore.getState().setIsNotShowAds(false); // Show Ads to everyOne
      return;
    }
    useAdsStore.getState().setIsNotShowAds(isUserVipProperty); // Not Show Ads/bannerAds to vip only
    if (isUserVipProperty) {
      closeBannerAdFunc();
    }
  }, [isUserVipProperty, me?.user_uid]);
  useEffect(() => {
    ;
    (async () => {
      if (!me?.user_uid) {
        useProfileStore.getState().setIsUserVip(false); // no User
        return;
      }
      const userPermission = await getUserPermission();
      const userVipStatus = !!userPermission?.is_vip;
      const vipType = userPermission?.vip_type || 'non-vip';
      useProfileStore.getState().setVIPType(vipType);
      useAdsStore.getState().setIsNotShowAds(userVipStatus); // Not Show Ads/bannerAds to vip only
      useProfileStore.getState().setIsUserVip(userVipStatus);
    })();
  }, [accessToken, me?.user_uid]);
  useEffect(() => {
    if (route.pathname === '/embed/[id]') {
      setIsEmbed(true);
    } else if (GetLocoNowUrl.includes(route.pathname)) {
      setGetLocoNowUrl(true);
    } else {
      setIsEmbed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.pathname]);
  const {
    loading: hlsLoading
  } = useScript({
    src: 'https://cdn.jsdelivr.net/npm/hls.js@latest',
    async: true
  });
  const {
    loading: imaLoading
  } = useScript({
    src: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
    async: true
  });
  const {
    adsScriptLoaded
  } = useAppStore();
  useEffect(() => {
    if (hlsLoading) return;
    if (!hlsLoading && adsScriptLoaded && !imaLoading) {
      setUpAds();
    }
  }, [hlsLoading, adsScriptLoaded, imaLoading]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.VMAXSDK && !adsScriptLoaded) {
        useAppStore.getState().setAdsScriptLoaded(true);
        clearInterval(interval);
      }
    }, 100); // Check every 100 ms
    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  // @ts-ignore
  const Layout = Component.Layout || BaseLayout;
  return _jsxs(_Fragment, {
    children: [_jsx("script", {
      src: "https://media.vmax.com/websdk/1.3.11/ad.js"
    }), _jsx(CacheProvider, {
      value: cache,
      children: _jsx(Provider, {
        template: AlertTemplate,
        ...Alertoptions,
        children: _jsxs(GoogleOAuthProvider, {
          children: [_jsx(DefaultSeo, {
            ...SEO
          }), _jsx(PlayerExit, {}), _jsx(MiniPlayerEntry, {}), _jsx(GlobalStyles, {}), _jsx(LazyCss, {}), _jsx(Script, {
            src: "https://player.live-video.net/1.30.0/amazon-ivs-player.min.js",
            strategy: "beforeInteractive"
          }), isProduction() && GA_TRACKING_ID && _jsx(GoogleTagManager, {
            gtmId: (process.env.GA_TRACKING_ID as string)
          }), _jsx(Layout
          // @ts-ignore
          , {
            layoutOptions: Component.layoutOptions,
            children: _jsx(React.Fragment, {
              children: !isEmbed && !isGetLocoNowUrl ? _jsx(React.Fragment, {
                children: _jsxs(PageWrapper, {
                  children: [_jsx("div", {
                    id: "ins_container"
                  }), _jsx(Component, {
                    ...pageProps
                  })]
                })
              }) : _jsx(Component, {
                ...pageProps
              })
            })
          }), me?.user_uid ? !screen_isDesktop || loginBonus ? _jsx(Login, {}) : false : _jsx(Login, {})]
        })
      })
    })]
  });
}