import { isWebProd } from '@utils/platform';
import create from 'zustand';
import { persist } from 'zustand/middleware';
export const IS_HIDE_AD_IN_PIP = true;
type AdType = 'pre_roll' | 'mid_roll' | 'banner';
type BannerAdType = 'banner' | string;
type AdCountProperties = 'preRollAdsWatchedByUser' | 'midRollAdsWatchedByUser' | 'preRollAdsClosedByUser' | 'midRollAdsClosedByUser' | 'totalAdsWatchedOnStream' | 'totalAdsCloseOnStream' | 'totalBannerAdsClosed';
interface adInterface {
  id: string;
  name: string;
  delay: number;
  'total-duration': number;
  'playback-duration': number;
}
interface IbannerAd {
  id: string;
  name: string;
  delay: number;
  placement: string;
  numberOfClicks: number;
}
interface ZustandState {
  adWatchDuration: number;
  streamWatchDuration: number;
  lastInstreamAdTime: number | null;
  adPlaying: boolean;
  adMuted: boolean;
  ad: adInterface | null;
  adType: AdType | null;
  volume_state: number;
  duration: {
    currentTime: number;
    totalTime: number;
  };
  preRollAdsWatchedByUser: number;
  midRollAdsWatchedByUser: number;
  preRollAdsClosedByUser: number;
  midRollAdsClosedByUser: number;
  totalAdsWatchedOnStream: number;
  totalAdsCloseOnStream: number;
  totalBannerAdsClosed: number;
  adError: boolean;
  bannerAdError: boolean;
  bannerAd: IbannerAd[] | null;
  bannerAdLoaded: boolean;
  bannerAdType: BannerAdType | null;
  lastMuteStateOfPlayer: boolean | null;
  showInStreamAdControl: boolean;
  isNotShowAds: boolean;
}
interface ZustandActions {
  updateStreamWatchDuration: (duration: number) => void;
  updateLastInstreamAdTime: () => void;
  isPreRollAdRequired: () => boolean;
  isMidRollAdRequired: () => boolean;
  setAdMuted: (value: boolean) => void;
  setAdPlaying: (value: boolean) => void;
  setAdType: (value: AdType) => void;
  incrementAdCount: (property: AdCountProperties) => void;
  resetStreamAdCount: () => void;
  setAd: (value: adInterface | null) => void;
  setBannerAd: (value: IbannerAd | null) => void;
  setBannerAdType: (value: BannerAdType) => void;
  updateAdWatchDuration: (duration: number) => void;
  setAdError: (value: boolean) => void;
  setBannerAdError: (value: boolean) => void;
  updateAdClickCount: (id: string) => void;
  updateDuration: (value: {
    currentTime: number;
    totalTime: number;
  }) => void;
  updateVolume: (volume: number) => void;
  setIsBannerAdLoaded: (isLoaded: boolean) => void;
  setLastMuteState: (value: boolean | null) => void;
  setShowInStreamAdControl: (value: boolean) => void;
  setIsNotShowAds: (isNotShowAds: boolean) => void;
}
type MyStore = ZustandState & ZustandActions;
const initialState: ZustandState = {
  lastMuteStateOfPlayer: null,
  adWatchDuration: 0,
  streamWatchDuration: 0,
  lastInstreamAdTime: null,
  adPlaying: false,
  adMuted: false,
  adType: null,
  volume_state: 1,
  duration: {
    currentTime: 0,
    totalTime: 0
  },
  preRollAdsWatchedByUser: 0,
  midRollAdsWatchedByUser: 0,
  preRollAdsClosedByUser: 0,
  midRollAdsClosedByUser: 0,
  totalAdsWatchedOnStream: 0,
  totalAdsCloseOnStream: 0,
  totalBannerAdsClosed: 0,
  showInStreamAdControl: false,
  ad: null,
  bannerAd: null,
  adError: false,
  bannerAdError: false,
  bannerAdLoaded: false,
  bannerAdType: null,
  isNotShowAds: false
};
const persistConfig = {
  name: 'zustand-persist',
  // Unique identifier for the persisted state
  getStorage: () => localStorage,
  // Storage medium (e.g., localStorage)
  partialize: (state: MyStore) => ({
    lastInstreamAdTime: state.lastInstreamAdTime
  }) // Persist only lastInstreamAdTime
};

const zustandStore = create<MyStore>(persist((set, get) => ({
  ...initialState,
  setIsNotShowAds: isNotShowAds => set({
    isNotShowAds: !!isNotShowAds
  }),
  updateVolume: (volume: number) => {
    volume = Math.max(0, Math.min(1, +(volume || 0).toFixed(2)));
    set({
      volume_state: volume
    });
  },
  updateDuration: ({
    currentTime,
    totalTime
  }: {
    currentTime: number;
    totalTime: number;
  }) => {
    currentTime = +(currentTime || 0).toFixed(2);
    totalTime = +(totalTime || 0).toFixed(2);
    set({
      duration: {
        currentTime,
        totalTime
      }
    });
  },
  updateAdWatchDuration: (duration: number) => {
    set({
      adWatchDuration: duration
    });
  },
  updateStreamWatchDuration: (duration: number) => {
    set({
      streamWatchDuration: duration
    });
  },
  updateLastInstreamAdTime: () => {
    set({
      lastInstreamAdTime: Date.now()
    });
  },
  isPreRollAdRequired: () => {
    const {
      lastInstreamAdTime
    } = ((get() as unknown) as MyStore);
    if (!lastInstreamAdTime) {
      return true;
    }
    const minutesSinceLastAd = (Date.now() - lastInstreamAdTime) / (1000 * 60);
    if (!isWebProd()) {
      return minutesSinceLastAd >= 1;
    }
    return minutesSinceLastAd >= 6;
  },
  isMidRollAdRequired: () => {
    const {
      streamWatchDuration,
      lastInstreamAdTime
    } = ((get() as unknown) as MyStore);
    if (!lastInstreamAdTime) {
      return true;
    }
    const minutesSinceLastAd = (Date.now() - lastInstreamAdTime) / (1000 * 60);
    if (!isWebProd()) {
      return minutesSinceLastAd >= 1 && streamWatchDuration > 60;
    }
    // for loco.gg
    return minutesSinceLastAd >= 6 && streamWatchDuration > 360;
  },
  setAdMuted: (value: boolean) => {
    set({
      adMuted: !!value
    });
  },
  setAdPlaying: (value: boolean) => {
    set({
      adPlaying: !!value
    });
  },
  setAdType: (value: AdType) => {
    set({
      adType: value
    });
  },
  setBannerAdType: (value: BannerAdType) => {
    set({
      bannerAdType: value
    });
  },
  setAd: (value: adInterface | null) => {
    set({
      ad: value
    });
  },
  setBannerAd: (value: IbannerAd | null) => {
    const {
      bannerAd
    } = get();
    // if (bannerAd?.some((item) => item.id === value?.id)) return
    const newArray = bannerAd ? [...bannerAd, {
      ...value,
      numberOfClicks: 0
    }] : [{
      ...value,
      numberOfClicks: 0
    }];
    set({
      bannerAd: (newArray as IbannerAd[])
    });
  },
  incrementAdCount: (property: AdCountProperties) => {
    set(state => {
      const updatedState = {
        ...state
      };
      updatedState[property] = state[property] + 1;
      return updatedState;
    });
  },
  resetStreamAdCount: () => {
    set({
      totalAdsCloseOnStream: 0,
      totalAdsWatchedOnStream: 0,
      adWatchDuration: 0
    });
  },
  setAdError: (value: boolean) => {
    set({
      adError: value
    });
  },
  setBannerAdError: (value: boolean) => {
    set({
      bannerAdError: value
    });
  },
  updateAdClickCount: (id: string) => {
    const {
      bannerAd
    } = get();
    set({
      bannerAd: (bannerAd?.map(item => item.id === id ? {
        ...item,
        numberOfClicks: item.numberOfClicks + 1
      } : item) as IbannerAd[])
    });
  },
  setIsBannerAdLoaded: (value: boolean) => {
    set({
      bannerAdLoaded: value
    });
  },
  setLastMuteState: value => {
    set({
      lastMuteStateOfPlayer: value
    });
  },
  setShowInStreamAdControl: (value: boolean) => {
    set({
      showInStreamAdControl: value
    });
  }
}), persistConfig));
export const useAdsStore = zustandStore;