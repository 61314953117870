import { PLAYER_TYPE } from '@components/VideoPlayer/constants';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface IProps {
  onClickLive: () => void;
}
const BackToLive = ({
  onClickLive
}: IProps) => {
  const activePlayerType = usePlayerStore(state => state.activePlayerType);
  const isLivePlayerActive = activePlayerType === PLAYER_TYPE.LIVE;
  return _jsxs("div", {
    "aria-disabled": isLivePlayerActive,
    className: 'player-controls-btn back-to-live control-tooltip-parent ' + (isLivePlayerActive ? 'player_live' : 'player_vod'),
    children: [_jsx("span", {
      className: "control-tooltip",
      children: isLivePlayerActive ? 'Live' : 'Go Live'
    }), !isLivePlayerActive ? _jsx("button", {
      "aria-hidden": true,
      className: "live-text go-live",
      onPointerDown: onClickLive,
      children: "GO LIVE"
    }) : _jsxs("div", {
      className: "live-text",
      children: [_jsx("div", {
        className: "bold-bullet"
      }), "LIVE"]
    })]
  });
};
export default BackToLive;