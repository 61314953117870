import { MeInterface, STREAMER_TYPE, StreamInterface } from '@customTypes/index';
import { fetchTopRanks } from '@functions/instreamLeaderboard/normalLeaderboard';
import { IPageInfo } from '@stores/appStore';
import ShortUrlGetter from '@utils/shortUrlGetter';
import { UrlType } from '../../types';
import { ISourceTrackingIngo } from './../../stores/appStore';
import { LeaderBoardData } from './types';
export async function getLeaderBoardData(streamer_id: string): Promise<LeaderBoardData> {
  const [dailyRes, weeklyRes, monthlyRes] = await Promise.all([fetchTopRanks(streamer_id, 10, 0, 10, 0), fetchTopRanks(streamer_id, 20, 0, 10, 0), fetchTopRanks(streamer_id, 30, 0, 10, 0)]);
  return dailyRes?.results?.length > 0 ? {
    dayType: 'Daily',
    results: dailyRes?.results
  } : weeklyRes?.results?.length > 0 ? {
    dayType: 'Weekly',
    results: weeklyRes?.results
  } : monthlyRes?.results?.length > 0 ? {
    dayType: 'Monthly',
    results: monthlyRes?.results
  } : {
    dayType: '',
    results: []
  };
}
type GetShareUrl = {
  pageInfo: IPageInfo;
  stream: StreamInterface;
  me: MeInterface | null;
};
export async function getShareUrl({
  pageInfo,
  stream,
  me
}: GetShareUrl) {
  if (pageInfo?.page) {
    const query = {
      type: '5',
      stream_uid: stream.uid,
      af_web_dp: `https://loco.gg/stream/${stream.uid}`,
      c: pageInfo.page,
      af_adset_id: me?.user_uid ?? 'web_no_login',
      af_adset: me?.username ?? 'web_no_login',
      af_ad_id: stream?.streamer?.user_uid ?? pageInfo.page,
      af_ad: stream?.streamer?.username ?? pageInfo.page,
      af_ad_type: stream?.status === 30 ? 'vod' : 'stream',
      af_channel: stream?.categories[0]?.label ?? 'NA',
      af_siteid: stream?.uid ?? 'webpage',
      af_sub_siteid: stream?.categories ? stream?.categories[0]?.uid ?? 'webpage' : 'webpage'
    };
    const short_url = await ShortUrlGetter({
      query,
      deepLinkRID: me?.user_uid ? me?.user_uid : '',
      urlType: UrlType.TYPE_VIDEO
    });
    return short_url;
  }
}
export function getViewEventProperties(stream: StreamInterface) {
  return {
    streamer_id: stream?.streamer?.user_uid,
    streamer_name: stream?.streamer?.username,
    category_id: stream?.game_uid,
    video_id: stream?.uid,
    video_view_count: stream?.currrentViews,
    category_name: stream?.game_name,
    streamer_type: stream?.streamer.user_type,
    streamer_category: stream?.streamer?.user_type === STREAMER_TYPE.partner ? 'partner ' : stream.streamer.user_type === STREAMER_TYPE.organic ? 'organic' : stream.streamer.user_type === STREAMER_TYPE.admin ? 'admin' : stream.streamer.user_type === STREAMER_TYPE.premium ? 'premium' : stream.streamer.user_type === STREAMER_TYPE.viewer ? 'viewer' : 'viewer',
    //@ts-ignore
    video_tags: stream.tags
  };
}
export type GetSourceEventPropertiesType = {
  source_page_name: string | undefined;
  source_sidenav: string;
  source_page_streamer_name: string | undefined;
  source_page_streamer_id: string | undefined;
  source_page_category_name: string | undefined;
  source_page_category_id: string | undefined;
  source_page_video_id: string | undefined;
  source_component_name?: string;
  source_component_type?: string;
  source_component_v_position?: number;
  source_component_h_position?: number;
  source_component_size?: number;
  source_component_highlighted?: string;
  source_position_in_source_component?: number;
  source_view_all_in_source_component?: string;
  source_ingredient_name?: string;
  source_ingredient_position?: number;
  source_ingredient_size?: number;
  source_position_in_ingredient?: number;
  is_suggested?: boolean;
};
export function getSourceEventProperties(event: ISourceTrackingIngo): GetSourceEventPropertiesType {
  return {
    source_page_name: event?.sourcePageName !== 'NA' ? event?.sourcePageName : undefined,
    source_sidenav: event?.sourceSidenav ? 'yes' : 'no',
    source_page_streamer_name: event?.sourcePageStreamerName !== 'NA' ? event?.sourcePageStreamerName : undefined,
    source_page_streamer_id: event?.sourcePageStreamerId !== 'NA' ? event?.sourcePageStreamerId : undefined,
    source_page_category_name: event?.sourcePageCategoryName !== 'NA' ? event?.sourcePageCategoryName : undefined,
    source_page_category_id: event?.sourcePageCategoryId !== 'NA' ? event?.sourcePageCategoryId : undefined,
    source_page_video_id: event?.sourcePageVideoId !== 'NA' ? event?.sourcePageVideoId : undefined,
    source_component_name: event?.source_component_name !== 'NA' ? event?.source_component_name : undefined,
    source_component_type: event?.source_component_type !== 'NA' ? event?.source_component_type : undefined,
    source_component_v_position: event?.source_component_v_position,
    source_component_h_position: event?.source_component_h_position,
    source_component_size: event?.source_component_size,
    source_component_highlighted: event?.source_component_highlighted ? 'yes' : 'no',
    source_position_in_source_component: event?.source_position_in_source_component,
    source_view_all_in_source_component: event?.source_view_all_in_source_component !== 'NA' ? event?.source_view_all_in_source_component : undefined,
    source_ingredient_name: event?.source_ingredient_name !== 'NA' ? event?.source_ingredient_name : undefined,
    source_ingredient_position:
    //@ts-ignore
    event?.source_ingredient_position !== 'NA' ? event?.source_ingredient_position : undefined,
    source_ingredient_size:
    //@ts-ignore
    event?.source_ingredient_size !== 'NA' ? event?.source_ingredient_size : undefined,
    source_position_in_ingredient:
    //@ts-ignore
    event?.source_position_in_ingredient !== 'NA' ? event?.source_position_in_ingredient : undefined,
    is_suggested: event.is_suggested ? event.is_suggested : undefined
  };
}