function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import FadeEffect from '@components/FadeEffectElement';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useStreamStore } from '@stores/streamStore';
import { increaseCount, updateMetric } from '@stores/usePlayerEvents';
import React from 'react';
import MiniPlayerToogle from './MiniPlayerToggle';
import MoveMiniPlayer from './MoveMiniPlayer';
import PlayPauseToggle from './PlayPauseToggle';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsPortalOpen
} = usePlayerStore.getState();
const {
  setIsPlaying
} = useStreamStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1487yr9",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;display:flex;align-items:center;justify-content:center"
} : {
  name: "1n51ze2-MiniPlayerControls",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;display:flex;align-items:center;justify-content:center;label:MiniPlayerControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "yoovvv",
  styles: "position:absolute;left:0.75rem;right:0.75rem;top:0.75rem;z-index:100;display:flex;align-items:center;justify-content:space-between;border-radius:0.375rem"
} : {
  name: "8py2sk-MiniPlayerControls",
  styles: "position:absolute;left:0.75rem;right:0.75rem;top:0.75rem;z-index:100;display:flex;align-items:center;justify-content:space-between;border-radius:0.375rem;label:MiniPlayerControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1wnowod",
  styles: "display:flex;align-items:center;justify-content:center"
} : {
  name: "gw2a8l-MiniPlayerControls",
  styles: "display:flex;align-items:center;justify-content:center;label:MiniPlayerControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "dopfyw",
  styles: "padding-left:0.5rem;padding-bottom:0.25rem;padding-top:0.125rem;padding-right:0.25rem"
} : {
  name: "1uj4dia-MiniPlayerControls",
  styles: "padding-left:0.5rem;padding-bottom:0.25rem;padding-top:0.125rem;padding-right:0.25rem;label:MiniPlayerControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const MiniPlayerControls = () => {
  const isLoading = usePlayerStore(state => state.isLoading);
  const isBuffering = useStreamStore(state => state.isBuffering);
  const stream = usePlayerStore(state => state.playerProps.stream);
  const isPlaying = useStreamStore(state => state.isPlaying);
  return _jsxs(React.Fragment, {
    children: [_jsx(MoveMiniPlayer, {}), _jsx(FadeEffect, {
      isOpen: !isLoading && !isBuffering,
      children: ({
        isVisible
      }) => _jsx("div", {
        css: _ref,
        style: {
          opacity: isVisible ? 1 : 0
        },
        children: _jsx(PlayPauseToggle, {
          onClick: () => {
            increaseCount(stream?.uid || '', 'player_play_pause_toggle_count', 1);
            setIsPlaying(!isPlaying);
          },
          isPaused: !isPlaying,
          isDisabled: false,
          isPortalOpen: true
        })
      })
    }), _jsxs("div", {
      css: _ref2,
      children: [_jsx(MiniPlayerToogle, {}), _jsx("div", {
        css: _ref3,
        children: _jsx("button", {
          className: "mini-player-exit",
          onPointerDown: () => {
            updateMetric(stream?.uid || '', 'player_pip_close_button_clicked', true);
            setIsPlaying(false);
            setIsPortalOpen(false, true);
          },
          css: _ref4,
          children: _jsx("svg", {
            width: "14",
            height: "14",
            viewBox: "0 0 14 14",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
            children: _jsx("path", {
              d: "M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z",
              fill: "white"
            })
          })
        })
      })]
    })]
  });
};
export default MiniPlayerControls;