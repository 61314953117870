import { PLAYER_TYPE } from '@components/VideoPlayer/constants';
import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useCountMetric } from '@components/VideoPlayer/Utils/helper';
import { isiOS } from '@components/VideoPlayer/Utils/utils';
import { getStreamData } from '@functions/api';
import { getReportActions } from '@functions/service';
import useDelayEffect from '@hooks/useDelayEffect';
import { logStreamerErrorEvent, logStreamStatsEvent } from '@layouts/stream/unicornLogFun';
import { closeAdFunc } from '@modules/ads/setUpAds';
import { useAdsStore } from '@stores/adsStore';
import { useAppStore } from '@stores/appStore';
import { useStreamStore } from '@stores/streamStore';
import { appendMetric, fetchEventData, updateMetric } from '@stores/usePlayerEvents';
import { PlayerState } from 'amazon-ivs-player';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import * as workerTimers from 'worker-timers';
import { WithPlayerContext } from '../../store/WithContextProvider';
import usePlayer from './usePlayer';
import usePlayerAudioState from './usePlayerAudioState';
import usePlayerPlayPauseState from './usePlayerPlayPauseState';
import usePlayerPositionStuff from './usePlayerPositionStuff';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const {
  setIsVodPlayerDisabled,
  setIsLoading,
  setPlayerPosition,
  setPlayerDuration,
  setSeekbarPosition,
  setReportActions,
  setIsEnded,
  setPlayerProps,
  setPlayerError,
  setPlayerState,
  resetPlayerLogs,
  setPlayerStateLogs
} = usePlayerStore.getState();
const {
  setIsBuffering,
  setIsPlaying
} = useStreamStore.getState();
const {
  resetControlSectionVisiblity,
  setIsSeekbarDisabled
} = useControlDisplayStore.getState();
const InitializeVideoPlayer = (props: {
  children?: React.ReactNode;
}) => {
  const [isFirstChunkLoaded, setIsFirstChunkLoaded] = useState(false);
  const ad = useAdsStore(state => !!state.ad?.id);
  const adsScriptLoaded = useAppStore(state => state.adsScriptLoaded);
  const isAdPresent = ad && adsScriptLoaded;
  const isBuffering = useStreamStore(state => state.isBuffering);
  const isVodPlayerDisabled = usePlayerStore(state => state.isVodPlayerDisabled);
  const playerError = usePlayerStore(state => state.playerError);
  const isEnded = usePlayerStore(state => state.isEnded);
  const playerDuration = usePlayerStore(state => state.playerDuration);
  const isSeekbarClicked = useControlDisplayStore(state => state.isSeekbarClicked);
  const isPlaying = useStreamStore(state => state.isPlaying);
  const activePlayerType = usePlayerStore(state => state.activePlayerType);
  const previousActivePlayerType = usePrevious(activePlayerType);
  const {
    playbackUrl,
    vodPlaybackUrl,
    stream
  } = usePlayerStore(state => state.playerProps);
  const isPlayerDurationPresent = isFinite(playerDuration) && playerDuration > 0;
  const isChannelLive = stream?.status === 20;
  const livePlayerRef = usePlayer(playbackUrl, {
    type: PLAYER_TYPE.LIVE,
    isChannelLive,
    stream_infra_type: stream?.stream_infra_type
  });
  const vodPlayerRef = usePlayer(vodPlaybackUrl, {
    type: PLAYER_TYPE.VOD,
    isChannelLive,
    stream_infra_type: stream?.stream_infra_type
  });
  const isLivePlayerAvailable = isChannelLive && !livePlayerRef.error && livePlayerRef.isReady;
  const isLivePlayerActive = activePlayerType === PLAYER_TYPE.LIVE;
  const activePlayerRef = isLivePlayerActive ? livePlayerRef : vodPlayerRef;
  const inActivePlayerRef = !isLivePlayerActive ? livePlayerRef : vodPlayerRef;
  const activePlayerTimeToLoad = isChannelLive ? livePlayerRef.timeToLoad : vodPlayerRef.timeToLoad;
  const isVideoBuffering = !!activePlayerRef.isBuffering;
  const isVideoInitialized = !activePlayerRef.error && activePlayerRef.isReady;
  const isLivePlayerPlaying = !livePlayerRef.isPaused;
  const isVodPlayerPlaying = !vodPlayerRef.isPaused;
  usePlayerAudioState({
    isFirstChunkLoaded,
    livePlayerRef,
    vodPlayerRef
  });
  usePlayerPositionStuff({
    isFirstChunkLoaded,
    livePlayerRef,
    vodPlayerRef
  });
  const {
    onSeekToThisPosition
  } = usePlayerPlayPauseState({
    isFirstChunkLoaded,
    livePlayerRef,
    vodPlayerRef
  });
  const SeekToRef = useRef(onSeekToThisPosition);
  SeekToRef.current = onSeekToThisPosition;

  // useEffect(() => {
  //   // Test Code::: Only for stage2, localhost etc
  //   window.__usePlayerStore = usePlayerStore.getState()
  //   window.__seekTo = (n: number) => vodPlayerRef.seekTo(n)
  //   window.__useControlDisplayStore = useControlDisplayStore.getState()
  //   window.__useStreamStore = useStreamStore.getState()
  // })

  // Video Quality change, event listener
  {
    const isUserInView = useCountMetric();
    const isUserInViewRef = useRef(isUserInView);
    isUserInViewRef.current = isUserInView;
    const cq = (activePlayerRef.isAutoQualityMode ? 'AUTO ' : '') + (activePlayerRef.quality?.name || '');
    const previousQuality = usePrevious(cq);
    useEffect(() => {
      if (activePlayerRef.isReady) {
        // Only adding initial bitrate once
        updateMetric(stream?.uid || '', 'initial_bitrate', cq);
      }
    }, [activePlayerRef.isReady]);
    useEffect(() => {
      const ActiveStreamId = stream?.uid || '';
      const {
        stream: c_stream
      } = useStreamStore.getState();
      const {
        stream_info
      } = fetchEventData(ActiveStreamId || '');
      const stream_ = stream_info || c_stream;
      if (!activePlayerRef.isReady || !stream_?.uid) {
        // just return if not ready
        return;
      }
      if (
      // Not fire, when not in view
      !isUserInViewRef.current || !previousQuality || previousQuality === cq) {
        return;
      }
      appendMetric(stream_.uid, 'manual_bitrate_change',
      //  So that we know what has been changed
      cq);
      logStreamStatsEvent({
        stream: stream_,
        source: activePlayerRef.isAutoQualityMode ? 'autobitrate_change' : 'bitrange_change'
      });
    }, [activePlayerRef.isReady, previousQuality, cq]);
  }
  useEffect(() => {
    if (!window.VMAXSDK || !isAdPresent || playerError) return;
    window.VMAXSDK.showAd({
      placementId: 'a1' //< showing Video ad on 'a1'
      // containerId: 'video_container_locogg_ads',
    });
    // No need to add `hasError` Deps
  }, [isAdPresent]);
  useEffect(() => {
    const isFatalError = !!activePlayerRef.error;
    const CurrentError = activePlayerRef.error || activePlayerRef.internalError;
    if (!CurrentError) {
      setPlayerError(null);
      return;
    }
    if (isFatalError && CurrentError) {
      //error-> if error from activePlayer then show loader and hide control
      const stream = useStreamStore.getState().stream;
      stream && closeAdFunc(stream);
      setIsPlaying(false);
      setPlayerError(CurrentError);
    }
    logStreamerErrorEvent({
      is_startup: !!activePlayerRef.isInitialLoading,
      error_code: CurrentError.code,
      error_message: CurrentError.message,
      error_type: CurrentError.type,
      player_type: activePlayerRef.type,
      is_fatal_error: isFatalError
    });

    // incase of Fatal error for rewind.m3u8, we try to go to live & disable VOD
    if (isFatalError && isChannelLive && !isLivePlayerActive) {
      setIsVodPlayerDisabled(true); // will reset when user change the stream
      setPlayerError(null);
      const playerDuration = usePlayerStore.getState().playerDuration;
      // Move to latest
      SeekToRef.current({
        position: playerDuration,
        duration: playerDuration,
        progress: 100
      });
    }
  }, [activePlayerRef.error, activePlayerRef.internalError]);
  useEffect(() => {
    if (activePlayerRef.isInitialLoading) {
      return;
    }
    const _playerStateLogs = usePlayerStore.getState().playerStateLogs;
    if (_playerStateLogs.length === 0 || previousActivePlayerType !== activePlayerType) {
      setPlayerStateLogs(activePlayerType.toLowerCase());
    }
    setPlayerState(activePlayerRef.activeState);
    setPlayerStateLogs(activePlayerRef.activeState);
  }, [activePlayerRef.activeState, activePlayerRef.isInitialLoading, activePlayerType]);
  useEffect(() => {
    setIsLoading(!isVideoInitialized);
  }, [isVideoInitialized]);
  useEffect(() => {
    updateMetric(stream?.uid || '', 'web_perf_stream_start_time_ms', activePlayerTimeToLoad);
  }, [activePlayerTimeToLoad, stream?.uid]);
  useEffect(() => {
    if (isPlayerDurationPresent) {
      setIsFirstChunkLoaded(true);
    }
  }, [isPlayerDurationPresent, stream?.uid]);
  useEffect(() => {
    if (vodPlayerRef.hasEnded && isChannelLive) {
      const playerDuration = usePlayerStore.getState().playerDuration;
      SeekToRef.current({
        position: playerDuration,
        duration: playerDuration,
        progress: 100
      });
      setIsEnded(false);
    } else if (vodPlayerRef.hasEnded) {
      setIsEnded(true);
      // setPlayerProps(null)
    } else {
      setIsEnded(false);
    }
  }, [vodPlayerRef.hasEnded, isChannelLive]);
  useEffect(() => {
    if (!isBuffering || !isLivePlayerActive || !isLivePlayerAvailable) {
      return;
    }
    // Method to check if live is buffering for more that 15 seconds...
    // Check if LIVE player ends
    let isMounted = true;
    const fetchStreamDetails = async () => {
      const streamUid = usePlayerStore.getState().playerProps.stream?.uid;
      if (!streamUid) return;
      const response = await getStreamData(streamUid);
      if (!response) {
        return;
      }
      await new Promise(res => setTimeout(() => res(''), 1500));
      const TillNowStreamUid = usePlayerStore.getState().playerProps.stream?.uid;
      if (!isMounted || TillNowStreamUid !== streamUid) {
        return;
      }
      const {
        status,
        duration,
        started_at,
        thumbnail_url_small,
        stream_infra_type
      } = response || {};
      const isStillLive = status === 20;
      const activeStream = useStreamStore.getState().stream;
      if (response?.uid === activeStream?.uid) {
        // Overwrite response data with updated stream details
        useStreamStore.getState().setStream(response);
      }
      if (!isStillLive) {
        // Set stream ended here
        setIsEnded(true);
        // setPlayerProps(null)
      } else {
        setPlayerProps({
          stream: {
            uid: streamUid,
            status: 20,
            duration,
            started_at,
            thumbnail_url_small,
            stream_infra_type
          }
        });
      }
    };
    const timer = workerTimers.setTimeout(() => {
      fetchStreamDetails();
    }, 15 * 1000);
    return () => {
      workerTimers.clearTimeout(timer);
      isMounted = false;
    };
  }, [isLivePlayerActive, isLivePlayerAvailable, isBuffering]);
  useEffect(() => {
    if (!isFirstChunkLoaded) {
      return;
    }
    let i = 0;
    const MULTIPLIER = [1, 60, 60 * 60];
    const window_query_param = typeof window !== 'undefined' ? new URL(window.location.href).searchParams.get('t') : null;
    const time_in_seconds = (window_query_param || '').split(':').reduceRight((acc, value) => {
      const index = i++;
      acc = acc + +value * MULTIPLIER[index];
      return acc;
    }, 0);
    if (time_in_seconds > 0) {
      const playerDuration = usePlayerStore.getState().playerDuration;
      SeekToRef.current({
        position: time_in_seconds,
        duration: playerDuration,
        progress: -1
      });
    }
  }, [isFirstChunkLoaded]);
  useEffect(() => {
    // To pause other player, Only one player should be playing at any given time
    if (!isChannelLive) {
      return;
    }
    if (isLivePlayerActive && isVodPlayerPlaying) {
      vodPlayerRef.pause();
    } else if (!isLivePlayerActive && isLivePlayerPlaying) {
      livePlayerRef.pause();
    }
  }, [isChannelLive, isLivePlayerActive, isLivePlayerPlaying, isVodPlayerPlaying]);
  useEffect(() => {
    setIsBuffering(isVideoBuffering);
  }, [isVideoBuffering]);
  const isCalculateVodPositionManually = !isSeekbarClicked && isPlaying && !vodPlayerRef.isSeekbarSeekPending;
  useEffect(() => {
    if (isLivePlayerActive) {
      // Setting seekbar to full
      setSeekbarPosition(100);
      return;
    }
    if (isCalculateVodPositionManually) {
      const timer = workerTimers.setInterval(() => {
        const {
          playerPosition,
          playerDuration
        } = usePlayerStore.getState();
        const progress = Math.round(playerPosition / playerDuration * 10000000) / 100000;
        setSeekbarPosition(progress);
      }, 1000);
      return () => {
        workerTimers.clearInterval(timer);
      };
    }
  }, [isCalculateVodPositionManually, isLivePlayerActive]);
  useDelayEffect(() => {
    getReportActions().then(reportActions => {
      setReportActions(reportActions?.results || null);
    });
  }, []);
  useEffect(() => {
    if (isEnded) {
      setIsPlaying(false);
      setIsBuffering(false);
      setIsLoading(false);
    }
  }, [isEnded]);
  const isLivePlayerInitialized = isChannelLive && isLivePlayerAvailable && !isVodPlayerDisabled;
  useEffect(() => {
    if (isLivePlayerInitialized) {
      // Initializa vod player after live player is available
      vodPlayerRef.initializePlayer();
    }
  }, [isLivePlayerInitialized]);
  useLayoutEffect(() => {
    // This is Video Initializa Segment
    if (isChannelLive) {
      const isIPhoneDevice = isiOS();
      // disable seekbar & vod player in iOS mWeb
      setIsVodPlayerDisabled(isIPhoneDevice);
    } else {
      setIsVodPlayerDisabled(false);
    }
    setIsSeekbarDisabled(false);
    // Start playing Video
    setIsPlaying(true);
    resetControlSectionVisiblity();
    setPlayerState(PlayerState.IDLE);
    setIsFirstChunkLoaded(false);
    setPlayerDuration(0);
    setPlayerPosition(0);
    resetPlayerLogs();
    if (isChannelLive) {
      // Post this, we initialize VOD
      livePlayerRef.initializePlayer();
    } else {
      vodPlayerRef.initializePlayer();
    }
  }, [isChannelLive, stream?.uid]);
  return _jsx(WithPlayerContext, {
    onSeekToThisPosition: SeekToRef.current,
    setQuality: q => {
      activePlayerRef.setQuality(q);
      // Need to check if quality has present here or not
      const inActiveQuality = inActivePlayerRef.qualitites.filter(item => item.name === q.name)[0];
      if (inActiveQuality) {
        inActivePlayerRef.setQuality(inActiveQuality);
      } else {
        // IF not present, then set inActive to auto mode...
        inActivePlayerRef.setAutoQualityMode(true);
      }
    },
    setPlaybackRate: vodPlayerRef.setPlaybackRate // only vod, not live
    ,
    setAutoQualityMode: q => {
      // can set both player auto-quality
      livePlayerRef.setAutoQualityMode(q);
      vodPlayerRef.setAutoQualityMode(q);
    },
    isAutoQualityMode: activePlayerRef.isAutoQualityMode,
    quality: activePlayerRef.quality,
    qualitites: activePlayerRef.qualitites,
    playbackRate: activePlayerRef.playbackRate,
    children: props.children
  });
};
export default InitializeVideoPlayer;