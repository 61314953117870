import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface IgooglePlayStoreProps {
  width: string;
  height: string;
  className: string;
  isHovered: boolean;
}
const GooglePlayStore = (props: IgooglePlayStoreProps) => {
  const {
    isHovered,
    ...leftProps
  } = props;
  return _jsxs("svg", {
    viewBox: "0 0 32 32",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    ...leftProps,
    children: [_jsx("circle", {
      cx: 16,
      cy: 16,
      r: 16,
      fill: isHovered ? '#FFCC49' : '#fff'
    }), _jsx("path", {
      d: "m9.428 6 9.133 8.812 2.357-2.275c-3.22-1.885-7.534-4.414-9.473-5.552l-1.3-.76A1.654 1.654 0 0 0 9.428 6ZM8.034 7.055A1.569 1.569 0 0 0 8 7.354v17.413c0 .074.015.142.026.211l9.29-8.966-9.282-8.957Zm14.432 6.39-2.66 2.567 2.614 2.523 2.451-1.434c.47-.278.737-.697.729-1.155-.007-.45-.273-.85-.726-1.098-.1-.056-1.047-.608-2.408-1.403Zm-3.905 3.768L9.456 26c.177-.03.353-.08.522-.18l10.893-6.377-2.31-2.23Z",
      fill: "#050506"
    })]
  });
};
export default GooglePlayStore;