/* eslint-disable @typescript-eslint/no-empty-function */
import { STREAMER_TYPE } from '@customTypes/index';
import { Amplitude } from '@modules/analytics';
import { useAdsStore } from '@stores/adsStore';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { setupGlobalSetterOnAds } from '@utils/ads';
import { isWebProd } from '@utils/platform';
import dayjs from 'dayjs';
interface onReadyAdResponse {
  placement: string;
  adInfo: {
    order: {
      id: string;
      name: string;
    };
    advertiser: {
      id: string;
      marketer: string;
      brand: string;
      agency: string;
    };
    campaign: {
      id: string;
      name: string;
    };
    ad: {
      id: string;
      name: string;
      delay: number;
      'total-duration': number;
      'playback-duration': number;
    };
    adparams: null;
  };
}
export const placementIds: string[] = [];
const numberOfIds = 1000;
function logEventOnClick(data: any) {
  const adType = useAdsStore.getState().bannerAdType;
  const ad = useAdsStore.getState().bannerAd;
  if (!adType || !ad) return;
  useAdsStore.getState().updateAdClickCount(data?.adInfo?.ad?.id);
  const currAd = ad.find(item => item.id === data?.adInfo?.ad?.id);
  Amplitude.logEvent('click_ad', {
    ad_id: currAd?.id ?? data?.adInfo?.ad?.id,
    ad_type: adType,
    banner_ads_clicked: (currAd?.numberOfClicks ?? 0) + 1
  });
}
function logEventOnError(data: any) {
  const adType = useAdsStore.getState().bannerAdType;
  const error = data.error;
  useAdsStore.getState().setBannerAdError(true);
  Amplitude.logEvent('error_ad', {
    ad_type: adType,
    error_code: error.errorCode,
    error_description: error.errorDescription
  });
}

// 1. Load Event is not working
// 2. Error Event is working
// 3. Impression Event is not working
// 4. Close Event is not working
// 5. Click Event is not working

function bindEvents(pId: string) {
  if (!window.VMAXSDK) return;
  const events = [{
    eventType: window?.VMAXSDK?.Event?.Type?.AD_LOADED,
    callback: function (data: any) {
      console.log('>>>>>>>>>>> Data Loaded...!', data);
    }
  }, {
    eventType: window.VMAXSDK?.Event?.Type?.AD_RECEIVED,
    callback: function (data: any) {
      console.log('>>>>>>>>>>> Data Received...!', data);
    }
  }, {
    eventType: window.VMAXSDK?.Event?.Type?.AD_READY,
    callback: function (data: onReadyAdResponse | any) {
      const targetInsContainer = document.getElementById(`ins_banner_container_${data?.placement}`);
      targetInsContainer?.classList?.add('banner_active');
      const anchorElement = targetInsContainer?.querySelectorAll('a');
      console.log('>>>>>>>>>>> Data Ready', {
        data,
        anchorElement
      });
      useAdsStore.getState().setIsBannerAdLoaded(true);
      const adDetails = {
        ...data?.adInfo?.ad,
        placement: data?.placement
      };
      useAdsStore.getState().setBannerAd(adDetails);
      window.VMAXSDK?.showAd({
        placementId: data?.placement
        // containerId: 'banner_container_locogg_ads',
      });
    }
  }, {
    eventType: window.VMAXSDK?.Event?.Type?.AD_RENDER,
    callback: function (data: any) {
      console.log('>>>>>>>>>>>> Ad Rendered', data);
    }
  }, {
    eventType: window.VMAXSDK?.Event?.Type?.AD_REFRESH,
    callback: function (data: any) {
      console.log('>>>>>>>>>>>> Ad Refreshed', data);
    }
  }, {
    eventType: window.VMAXSDK?.Event?.Type?.AD_CLICK,
    callback: function (data: any) {
      console.log('>>>>>>>>>>>> Ad Clicked');
      logEventOnClick(data);
    }
  }, {
    eventType: window.VMAXSDK?.Event?.Type?.AD_ERROR,
    callback: function (data: any) {
      console.log('>>>>>>>>>>>> Ad Error', data);
      const insContainer = document.getElementById(`${data.placement}`);
      insContainer?.classList.remove('ins_banner_container');
      logEventOnError(data);
    }
  }];
  for (let i = 0; i < events.length; i++) {
    window.VMAXSDK.Events.listen(
    // @ts-ignore Supressing error because idk what is hapenning here :P
    events[i].eventType, pId, function (data: any) {
      events[i].callback(data);
    }.bind(events[i]));
  }
}
export default function setUpBannerAds() {
  const isLoggedIn = !!useAuthStore.getState().me?.user_uid;
  setupGlobalSetterOnAds(isLoggedIn);
  for (let i = 0; i <= numberOfIds; i++) {
    const pId = `b${i}`;
    placementIds.push(pId);
    bindEvents(pId);
  }
}
// now let's say se we get data-adsport id then we need to all data properties so we can get custom ads
export function insertBannerINSTag(adsSpotKey = '3e0b43a7', streamer_id?: string, game_id?: string, v_position?: number) {
  if (isWebProd() ||
  // not using bannerAds on prod
  !placementIds.length || !window.VMAXSDK || useAdsStore.getState().isNotShowAds) return;
  for (const pId of placementIds) {
    const insContainer = document?.getElementById('ins_banner_container_' + pId);
    const previousInsElement = document.getElementById(pId);
    const isLoggedIn = !!useAuthStore.getState().me?.user_uid;
    if (insContainer) {
      if (insContainer?.hasChildNodes() || previousInsElement) continue;
      const insElement = document.createElement('ins');
      insElement.id = pId;
      insElement.setAttribute('data-adspot-key', adsSpotKey);
      insElement.setAttribute('data-ad-container', 'banner_container_locogg_ads');
      insElement.setAttribute('data-full-width-responsive', 'true');
      const pageInfo = useAppStore.getState().pageInfo;
      const {
        os_name
      } = useAppStore.getState().deviceInfo;
      const userTypeNumber = useAuthStore.getState().me?.user_type;
      const userType = pageInfo?.streamerCategory ?? userTypeNumber === STREAMER_TYPE.viewer ? 'viewer' : userTypeNumber === STREAMER_TYPE.organic || userTypeNumber === STREAMER_TYPE.partner ? 'streamer' : 'guest';
      if ((v_position || streamer_id || game_id) && pageInfo) {
        insElement.dataset.setcustomdata = JSON.stringify({
          streamer_id: streamer_id,
          game_id: game_id,
          v_position: v_position,
          screen: pageInfo.page,
          deviceos: os_name,
          categoryId: pageInfo?.categoryId,
          user_type: userType
        });
      }
      if (isLoggedIn) {
        const birthdate = dayjs(useAuthStore.getState().me?.dob, 'DD/MM/YYYY');
        const ageInYears = dayjs().diff(birthdate, 'year');
        const email = useAuthStore.getState().me?.email;
        const gender = useAuthStore.getState().me?.gender;
        const genderInString = gender === 1 ? window.VMAXSDK?.Enums.Gender.MALE : gender === 2 ? window.VMAXSDK?.Enums.Gender.FEMALE : gender === 3 ? '' : '';
        if (ageInYears) {
          insElement.dataset.age = `${ageInYears}`;
        }
        if (email) {
          insElement.dataset.email = email;
        }
        if (genderInString) {
          insElement.dataset.gender = genderInString;
        }
      }
      insContainer?.appendChild(insElement);
      insContainer?.classList.add('ins_banner_container');
    }
  }
}
const removeInsBanner = (pId: string) => {
  const ins_container = document?.getElementById(`ins_banner_container_${pId}`);
  if (!ins_container) return;
  if (ins_container.hasChildNodes()) {
    if (ins_container.firstChild) {
      ins_container.removeChild(ins_container.firstChild);
    }
  }
  ins_container.classList.remove('ins_banner_container');
  ins_container.style.cssText = 'min-height: 0px !important; height: 0px !important;';
};
export const closeBannerAdFunc = () => {
  const ad = useAdsStore.getState().bannerAd;
  if (!ad) return;
  for (const adData of ad) {
    useAdsStore.getState().incrementAdCount('totalBannerAdsClosed');
    if (document.getElementById(adData.placement)) {
      removeInsBanner(adData.placement);
      Amplitude.addAmplitudeIdentity({
        banner_ads_closed: 1
      });
    }
  }
};
export const getBannerAdspotKey = () => {
  if (isWebProd()) {
    return {
      // For loco.gg
      HOME_PAGE_DESKTOP: 'V24574e78',
      LIVE_STREAM_DESKTOP: '53507eee',
      CLIPS_DESKTOP: 'V809447e6',
      STREAMER_PAGE_DESKTOP: '199d165c',
      CATEGORIES_DESKTOP: 'c3ef637f',
      MOBILE_ALL: 'ee884631',
      HOME_PAGE_MOBILE: '9c0388d2',
      LIVE_STREAM_MOBILE: '050ad968',
      CATEGORIES_MOBILE: '6c7b1ca6'
    };
  }
  return {
    // For stage2.loco.gg
    HOME_PAGE_DESKTOP: 'f7937770',
    LIVE_STREAM_DESKTOP: '9754fe95',
    CLIPS_DESKTOP: 'V809447e6',
    STREAMER_PAGE_DESKTOP: '199d165c',
    CATEGORIES_DESKTOP: '6e9a26ca',
    MOBILE_ALL: 'ee884631',
    HOME_PAGE_MOBILE: 'ee884631',
    LIVE_STREAM_MOBILE: 'ee884631',
    CATEGORIES_MOBILE: 'ee884631'
  };
};