import create, { SetState } from 'zustand';
import { MeInterface } from '../types';
type IAuthStore = {
  me: null | MeInterface;
  setMe: (value: any) => void;
  updateMe: (value: any) => void;
  accessToken: string | null;
  refreshToken: string | null;
  mode: string | null;
  userlogin: string | null;
  setAccessToken: (value: string) => void;
  setRefreshToken: (value: string) => void;
  loginBonus: string | null;
  setLoginBonus: (value: string) => void;
};
const store = (set: SetState<IAuthStore>): IAuthStore => ({
  me: null,
  setMe: value => set(() => ({
    me: value
  })),
  updateMe: value => set(({
    me
  }) => ({
    me: me ? {
      ...me,
      ...value
    } : me
  })),
  userlogin: null,
  mode: null,
  accessToken: null,
  refreshToken: null,
  setAccessToken: value => set(() => ({
    accessToken: value
  })),
  setRefreshToken: value => set(() => ({
    refreshToken: value
  })),
  loginBonus: '',
  setLoginBonus: value => set(() => ({
    loginBonus: value
  }))
});
export const useAuthStore = create<IAuthStore>(store);