import { jsx as _jsx } from "@emotion/react/jsx-runtime";
interface IosStoreProps {
  width: string;
  height: string;
  className: string;
  isHovered: boolean;
}
const IosStore = (props: IosStoreProps) => {
  const {
    isHovered,
    ...leftProps
  } = props;
  return _jsx("svg", {
    viewBox: "0 0 18 18",
    xmlns: "http://www.w3.org/2000/svg",
    ...leftProps,
    children: _jsx("path", {
      d: "M9 18C13.9703 18 18 13.9703 18 9C18 4.02975 13.9703 0 9 0C4.02975 0 0 4.02975 0 9C0 13.9703 4.02975 18 9 18ZM5.136 13.1475C4.9935 13.3748 4.74975 13.5 4.49925 13.5C4.3635 13.5 4.22625 13.4632 4.1025 13.386C3.75075 13.1662 3.64425 12.7035 3.864 12.3525L4.0665 12.0285C4.19625 12.012 4.3365 12 4.5 12C4.953 12 5.32875 12.1432 5.6445 12.3337L5.136 13.1475ZM4.5 11.25C4.086 11.25 3.75 10.914 3.75 10.5C3.75 10.086 4.086 9.75 4.5 9.75H5.49075L8.11575 5.55L7.239 4.1475C7.02 3.7965 7.1265 3.33375 7.4775 3.114C7.8285 2.895 8.29125 3.00225 8.511 3.3525L9 4.13475L9.489 3.3525C9.70875 3.00075 10.1715 2.8935 10.5225 3.114C10.8743 3.33375 10.9808 3.7965 10.761 4.1475L7.25925 9.75H9.6495C9.84075 9.88725 10.0522 10.1003 10.2758 10.4557C10.4415 10.719 10.5225 10.9875 10.5675 11.25H4.5ZM14.136 12.3525C14.355 12.7035 14.2485 13.1662 13.8975 13.386C13.7738 13.4632 13.6365 13.5 13.5007 13.5C13.251 13.5 13.0065 13.3748 12.864 13.1475L9.474 7.7235C9.534 7.57425 9.61125 7.41375 9.726 7.23075C9.93375 6.9 10.2 6.66975 10.4813 6.50475L12.5093 9.75H13.5C13.914 9.75 14.25 10.086 14.25 10.5C14.25 10.914 13.914 11.25 13.5 11.25H13.4468L14.136 12.3525Z",
      fill: isHovered ? '#FFCC49' : '#fff'
    })
  });
};
export default IosStore;