import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const playbackSpeed = _jsx(_Fragment, {
  children: _jsx("svg", {
    width: "14",
    height: "14",
    viewBox: "0 0 14 14",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: _jsx("path", {
      d: "M7.66664 0.366849V1.70018C10.5933 2.06018 12.6666 4.72018 12.3066 7.64685C12 10.0735 10.0933 12.0002 7.66664 12.2868V13.6202C11.3333 13.2535 14 10.0002 13.6333 6.33352C13.3333 3.16685 10.82 0.666849 7.66664 0.353516V0.366849ZM2.77997 12.1602C3.78982 13.002 5.02615 13.5262 6.3333 13.6668V12.3335C5.38491 12.2109 4.48691 11.8356 3.7333 11.2468L2.77997 12.1602ZM3.7333 2.82685C4.47997 2.22685 5.37997 1.84018 6.3333 1.70685V0.373516C5.0333 0.500182 3.7933 1.00018 2.77997 1.84018L3.7333 2.82685ZM2.7933 3.73352L1.83997 2.78018C1.00641 3.79175 0.49344 5.02891 0.366638 6.33352H1.69997C1.82664 5.38685 2.19997 4.48685 2.7933 3.73352ZM1.70664 7.66685H0.373305C0.506638 8.97352 1.01997 10.2068 1.84664 11.2202L2.7933 10.2668C2.20459 9.51325 1.82927 8.61525 1.70664 7.66685ZM5.66664 10.0002L9.66664 7.00018L5.66664 4.00018V10.0002Z",
      fill: "white"
    })
  })
});
export const videoQuality = _jsx(_Fragment, {
  children: _jsx("svg", {
    width: "14",
    height: "8",
    viewBox: "0 0 14 8",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: _jsx("path", {
      d: "M13.25 0.74L10 2.62V0.5L9.5 0H0.5L0 0.5V7.5L0.5 8H9.5L10 7.5V5.5L13.25 7.37L14 6.9V1.18L13.25 0.74ZM9 7H1V1H9V7ZM13 6L10 4.3V3.78L13 2V6Z",
      fill: "white"
    })
  })
});
export const keyboardShortcuts = _jsx(_Fragment, {
  children: _jsx("svg", {
    width: "16",
    height: "10",
    viewBox: "0 0 16 10",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: _jsx("path", {
      d: "M14.88 0.200195H1.12C0.5032 0.200195 0 0.703395 0 1.3202V8.6802C0 9.2954 0.5032 9.8002 1.1192 9.8002H14.8792C15.4952 9.8002 15.9992 9.2954 15.9992 8.6802V1.3202C16 0.703395 15.4952 0.200195 14.88 0.200195ZM8.8 1.8002H10.4V3.4002H8.8V1.8002ZM11.2 4.2002V5.8002H9.6V4.2002H11.2ZM6.4 1.8002H8V3.4002H6.4V1.8002ZM8.8 4.2002V5.8002H7.2V4.2002H8.8ZM4 1.8002H5.6V3.4002H4V1.8002ZM6.4 4.2002V5.8002H4.8V4.2002H6.4ZM1.6 1.8002H3.2V3.4002H1.6V1.8002ZM4 4.2002V5.8002H2.4V4.2002H4ZM3.2 8.2002H1.6V6.6002H3.2V8.2002ZM12 8.2002H4V6.6002H12V8.2002ZM14.4 8.2002H12.8V6.6002H14.4V8.2002ZM12 5.8002V4.2002H13.6V5.8002H12ZM14.4 3.4002H11.2V1.8002H14.4V3.4002Z",
      fill: "white"
    })
  })
});
export const reportVideo = _jsx(_Fragment, {
  children: _jsx("svg", {
    width: "13",
    height: "16",
    viewBox: "0 0 13 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: _jsx("path", {
      d: "M12.778 0.085C12.8462 0.130637 12.9022 0.192369 12.9409 0.264746C12.9796 0.337123 12.9999 0.417918 13 0.5V8C13 8.09985 12.9701 8.19741 12.9141 8.28011C12.8581 8.36281 12.7787 8.42685 12.686 8.464L12.5 8L12.686 8.464L12.683 8.465L12.677 8.468L12.654 8.477C12.5225 8.52925 12.3902 8.57926 12.257 8.627C11.993 8.722 11.626 8.85 11.21 8.977C10.394 9.229 9.331 9.5 8.5 9.5C7.653 9.5 6.952 9.22 6.342 8.975L6.314 8.965C5.68 8.71 5.14 8.5 4.5 8.5C3.8 8.5 2.862 8.73 2.063 8.977C1.70526 9.08849 1.35078 9.21021 1 9.342V15.5C1 15.6326 0.947321 15.7598 0.853553 15.8536C0.759785 15.9473 0.632608 16 0.5 16C0.367392 16 0.240215 15.9473 0.146447 15.8536C0.0526785 15.7598 0 15.6326 0 15.5V0.5C0 0.367392 0.0526785 0.240215 0.146447 0.146447C0.240215 0.0526784 0.367392 0 0.5 0C0.632608 0 0.759785 0.0526784 0.853553 0.146447C0.947321 0.240215 1 0.367392 1 0.5V0.782C1.226 0.703 1.496 0.612 1.79 0.522C2.606 0.272 3.67 0 4.5 0C5.34 0 6.024 0.277 6.621 0.519L6.664 0.537C7.286 0.788 7.828 1 8.5 1C9.2 1 10.138 0.77 10.937 0.523C11.3923 0.380812 11.8423 0.22204 12.286 0.047L12.305 0.04L12.309 0.038H12.31",
      fill: "white"
    })
  })
});
export const SelectedTitle = () => {
  return _jsx("div", {
    className: "setting-selected-icon",
    role: "img",
    "aria-label": "check",
    children: _jsx("svg", {
      viewBox: "0 0 24 24",
      xmlns: "http://www.w3.org/2000/svg",
      children: _jsx("path", {
        d: "M5.26039 11.6131L3 13.9552L8.80055 20L21 7.35936L18.723 5L8.80055 15.2813L5.26039 11.6131Z"
      })
    })
  });
};
export const UnSelectedButton = () => {
  return _jsx("div", {
    className: "setting-selected-icon",
    role: "img",
    "aria-label": "check",
    children: _jsxs("svg", {
      width: "14",
      height: "14",
      viewBox: "0 0 12 12",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: [_jsx("g", {
        clipPath: "url(#clip0_3065_100759)",
        children: _jsx("path", {
          d: "M6 0.75C4.96165 0.75 3.94662 1.05791 3.08326 1.63478C2.2199 2.21166 1.54699 3.0316 1.14963 3.99091C0.752275 4.95022 0.648307 6.00582 0.85088 7.02422C1.05345 8.04262 1.55347 8.97808 2.28769 9.71231C3.02192 10.4465 3.95738 10.9466 4.97578 11.1491C5.99418 11.3517 7.04978 11.2477 8.00909 10.8504C8.9684 10.453 9.78834 9.7801 10.3652 8.91674C10.9421 8.05339 11.25 7.03835 11.25 6C11.25 4.60761 10.6969 3.27226 9.71231 2.28769C8.72775 1.30312 7.39239 0.75 6 0.75ZM6 10.5C5.10999 10.5 4.23996 10.2361 3.49994 9.74161C2.75992 9.24715 2.18314 8.54434 1.84254 7.72208C1.50195 6.89981 1.41284 5.99501 1.58647 5.12209C1.7601 4.24918 2.18869 3.44736 2.81802 2.81802C3.44736 2.18868 4.24918 1.7601 5.1221 1.58647C5.99501 1.41283 6.89981 1.50195 7.72208 1.84254C8.54434 2.18314 9.24715 2.75991 9.74162 3.49993C10.2361 4.23996 10.5 5.10998 10.5 6C10.5 7.19347 10.0259 8.33807 9.18198 9.18198C8.33807 10.0259 7.19348 10.5 6 10.5Z",
          fill: "white"
        })
      }), _jsx("defs", {
        children: _jsx("clipPath", {
          id: "clip0_3065_100759",
          children: _jsx("rect", {
            width: "12",
            height: "12",
            fill: "white"
          })
        })
      })]
    })
  });
};
export const SelectedButton = () => {
  return _jsx("div", {
    className: "setting-selected-icon",
    role: "img",
    "aria-label": "check",
    children: _jsxs("svg", {
      width: "14",
      height: "14",
      viewBox: "0 0 12 12",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: [_jsxs("g", {
        clipPath: "url(#clip0_3065_100764)",
        children: [_jsx("path", {
          d: "M6 0.75C4.96165 0.75 3.94662 1.05791 3.08326 1.63478C2.2199 2.21166 1.54699 3.0316 1.14963 3.99091C0.752275 4.95022 0.648307 6.00582 0.85088 7.02422C1.05345 8.04262 1.55347 8.97808 2.28769 9.71231C3.02192 10.4465 3.95738 10.9466 4.97578 11.1491C5.99418 11.3517 7.04978 11.2477 8.00909 10.8504C8.9684 10.453 9.78834 9.7801 10.3652 8.91674C10.9421 8.05339 11.25 7.03835 11.25 6C11.25 4.60761 10.6969 3.27226 9.71231 2.28769C8.72775 1.30312 7.39239 0.75 6 0.75ZM6 10.5C5.10999 10.5 4.23996 10.2361 3.49994 9.74161C2.75992 9.24715 2.18314 8.54434 1.84254 7.72208C1.50195 6.89981 1.41284 5.99501 1.58647 5.12209C1.7601 4.24918 2.18869 3.44736 2.81802 2.81802C3.44736 2.18868 4.24918 1.7601 5.1221 1.58647C5.99501 1.41283 6.89981 1.50195 7.72208 1.84254C8.54434 2.18314 9.24715 2.75991 9.74162 3.49993C10.2361 4.23996 10.5 5.10998 10.5 6C10.5 7.19347 10.0259 8.33807 9.18198 9.18198C8.33807 10.0259 7.19348 10.5 6 10.5Z",
          fill: "white"
        }), _jsx("path", {
          d: "M6 3.75C5.55499 3.75 5.11998 3.88196 4.74997 4.12919C4.37996 4.37643 4.09157 4.72783 3.92127 5.13896C3.75097 5.5501 3.70642 6.0025 3.79323 6.43895C3.88005 6.87541 4.09434 7.27632 4.40901 7.59099C4.72368 7.90566 5.12459 8.11995 5.56105 8.20677C5.99751 8.29358 6.4499 8.24903 6.86104 8.07873C7.27217 7.90843 7.62357 7.62004 7.87081 7.25003C8.11804 6.88002 8.25 6.44501 8.25 6C8.25 5.40326 8.01295 4.83097 7.59099 4.40901C7.16903 3.98705 6.59674 3.75 6 3.75Z",
          fill: "white"
        })]
      }), _jsx("defs", {
        children: _jsx("clipPath", {
          id: "clip0_3065_100764",
          children: _jsx("rect", {
            width: "12",
            height: "12",
            fill: "white"
          })
        })
      })]
    })
  });
};
export const UpwardArrow = _jsx(_Fragment, {
  children: _jsx("svg", {
    width: "8",
    height: "8",
    viewBox: "0 0 8 8",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg",
    children: _jsx("path", {
      d: "M4.52455 7.4866V1.75197L7.0972 4.25734C7.3028 4.45756 7.6402 4.45756 7.8458 4.25734C8.0514 4.05712 8.0514 3.73368 7.8458 3.53345L4.37166 0.150168C4.16606 -0.0500562 3.83394 -0.0500562 3.62834 0.150168L0.154201 3.53345C-0.0514002 3.73368 -0.0514002 4.05712 0.154201 4.25734C0.359802 4.45756 0.691927 4.45756 0.897529 4.25734L3.47018 1.75197L3.47018 7.4866C3.47018 7.76897 3.70741 8 3.99736 8C4.28731 8 4.52455 7.76897 4.52455 7.4866Z",
      fill: "white"
    })
  })
});