import React from 'react';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface IProps {
  isPaused: boolean;
  onClick: (event: React.PointerEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isPortalOpen?: boolean;
}
const PlayPauseToggle = ({
  onClick,
  isPaused,
  isDisabled,
  isPortalOpen
}: IProps) => {
  return _jsxs("button", {
    "aria-disabled": !!isDisabled,
    className: `player-controls-btn play-pause-btn control-tooltip-parent ${isPaused ? 'play-btn' : 'pause-btn'} ${isPortalOpen ? 'play-pause-btn-mini-player' : ''}`,
    onPointerDown: onClick,
    children: [isPortalOpen ? _jsx(_Fragment, {}) : _jsx("span", {
      className: "control-tooltip",
      children: isPaused ? 'Play' : 'Pause'
    }), isPaused ? _jsx(_Fragment, {
      children: _jsx("svg", {
        width: "30",
        height: "30",
        viewBox: "0 0 44 44",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: _jsx("path", {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M13 14.801C13 13.8831 13 13.4242 13.1926 13.1606C13.3606 12.9307 13.6186 12.7834 13.9019 12.7554C14.2268 12.7234 14.6221 12.9565 15.4127 13.4227L28.4689 21.1218C29.238 21.5753 29.6226 21.8021 29.7524 22.0966C29.8657 22.3536 29.8657 22.6464 29.7524 22.9034C29.6226 23.1979 29.238 23.4247 28.4689 23.8782L15.4127 31.5773C14.6221 32.0435 14.2268 32.2766 13.9019 32.2446C13.6186 32.2166 13.3606 32.0693 13.1926 31.8394C13 31.5758 13 31.1169 13 30.199V14.801Z",
          fill: "white"
        })
      })
    }) : _jsx(_Fragment, {
      children: _jsx("svg", {
        width: "30",
        height: "30",
        viewBox: "0 0 44 44",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: _jsx("path", {
          d: "M13 32C13 32.5523 13.4477 33 14 33H18.16C18.7123 33 19.16 32.5523 19.16 32V13C19.16 12.4477 18.7123 12 18.16 12H14C13.4477 12 13 12.4477 13 13V32ZM26.32 12C25.7677 12 25.32 12.4477 25.32 13V32C25.32 32.5523 25.7677 33 26.32 33H30.48C31.0323 33 31.48 32.5523 31.48 32V13C31.48 12.4477 31.0323 12 30.48 12H26.32Z",
          fill: "white"
        })
      })
    })]
  });
};
export default PlayPauseToggle;