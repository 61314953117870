function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useDeviceInfo } from '@hooks/react-use';
import { useStreamStore } from '@stores/streamStore';
import { useEffect } from 'react';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  toggleTheatreMode
} = useStreamStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "190xlna",
  styles: "padding-left:0.25rem;padding-right:0.25rem"
} : {
  name: "1spsklw-TheatreMode",
  styles: "padding-left:0.25rem;padding-right:0.25rem;label:TheatreMode;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "oezi1x",
  styles: "height:1.275rem;min-height:1.275rem;width:1.275rem;min-width:1.275rem;border-width:0px;background-image:none"
} : {
  name: "1r9booz-TheatreMode",
  styles: "height:1.275rem;min-height:1.275rem;width:1.275rem;min-width:1.275rem;border-width:0px;background-image:none;label:TheatreMode;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const TheatreMode = ({
  disabled: isElementDisabled
}: {
  disabled?: boolean;
}) => {
  const {
    screen_isMobile
  } = useDeviceInfo();
  const {
    isFullScreen,
    theatreMode
  } = useStreamStore(state => ({
    isFullScreen: state.isFullScreen,
    theatreMode: state.theatreMode
  }));
  const isDisableTheatre = isFullScreen || screen_isMobile;
  useEffect(() => {
    if (isDisableTheatre) {
      toggleTheatreMode(false);
    }
  }, [isDisableTheatre]);
  if (isDisableTheatre) return _jsx(_Fragment, {});
  return _jsxs("button", {
    onClick: () => {
      toggleTheatreMode(!theatreMode);
    },
    disabled: isDisableTheatre || isElementDisabled,
    "aria-disabled": isDisableTheatre || isElementDisabled,
    className: `control-tooltip-parent theatre-toggle ` + (theatreMode ? 'theatre-toggle-on' : 'theatre-toggle-off'),
    css: _ref,
    children: [_jsx("span", {
      className: "control-tooltip",
      style: {
        left: '-290%'
      },
      children: theatreMode ? 'Exit Theatre Mode' : 'Enter Theatre Mode'
    }), _jsx("img", {
      src: "/newPlayer/theatre.svg",
      style: {
        opacity: isDisableTheatre || isElementDisabled ? 0.5 : 1
      },
      css: _ref2,
      alt: "theatre icon"
    })]
  });
};
export default TheatreMode;