export const GENDERS = [{
  value: '0',
  label: 'Select Gender'
}, {
  value: '1',
  label: 'Male'
}, {
  value: '2',
  label: 'Female'
}, {
  value: '3',
  label: 'Other'
}];
export const STREAMER_TYPES: {
  [key: number]: string;
} = ({
  1: 'partner',
  2: 'viewer',
  3: 'admin',
  10: 'organic',
  20: 'premium'
} as const);
export const LIVE_TEXT_WITH_ZERO_VIEWS = 'LIVE';