/**
 * Pages names used by Amplitude
 *
 * Each page within the /src/pages directory should use a different page name as "pageName".
 * This is used to track events happening within the pages, to know on which page they occurred.
 */
export enum AMPLITUDE_PAGES {
  HOME_PAGE = 'home',
  TRENADING_PAGE = 'trending',
  LIVE_NOW_PAGE = 'live_streams',
  VIDEOS_PAGE = 'videos',
  STREAMERS_PAGE = 'streamers',
  CATEGORIES_PAGE = 'categories',
  STREAMER_PAGE = 'streamer',
  CATEGORY_PAGE = 'category',
}
export const STREAMERS_PAGE_TYPE = ['videos', 'streams', 'clips', 'leaderboard', 'vip_leaderboard'];
export enum PAGES_ROUTE {
  HOME_PAGE = '/',
  TRENDING_PAGE = '/trending',
  LIVE_NOW_PAGE = '/live-streams',
  VIDEOS_PAGE = '/videos',
  STREAMERS_PAGE = '/streamers',
  CATEGORIES_PAGE = '/categories',
  STREAMER_PAGE = '/streamers/[...streamerSlug]',
  //'/streamers/[id]'
  CATEGORY_PAGE = '/categories/[id]',
  STREAM_PAGE = '/stream/[id]',
  CLIPS_PAGE = '/clips/[id]',
  ACTIVATE_PAGE = '/activate-tv',
  VIP_PAGE = '/vip',
}
export const follow_streamer = 'follow_streamer';
export const follow_category = 'follow_category';
export const unfollow_category_dismissed = 'unfollow_category_dismissed';
export const unfollow_streamer_dismissed = 'unfollow_streamer_dismissed';
export const search_query_web = 'search_query';
export const search_history_delete = 'search_history_delete';
export const comment_vod = 'comment_vod';
export const chat_stream = 'chat_stream';
export const VIEW_LEADERBOARD = 'view_leaderboard';
export const VISIT_ACHIEVEMENTS = 'visit_achievements';
export const allowed_events_for_bk: {
  [id: string]: boolean;
} = {
  login_otp: true,
  login_google: true,
  visit_stream: true,
  watch_stream: true,
  visit_vod: true,
  watch_vod: true,
  visit_clips: true,
  watch_clip: true,
  follow_streamer: true,
  gift_send: true,
  chat_stream: true,
  visit_web_home: true,
  visit_web_streamer_profile: true,
  visit_web_live_streams: true,
  visit_web_category_details: true,
  web_gold_icon_click: true,
  web_login_button_click: true,
  widget_interaction: true
};