export enum STREAMER_TYPE {
  'partner' = 1,
  'viewer' = 2,
  'admin' = 3,
  'organic' = 10,
  'premium' = 20,
}
export enum UrlType {
  TYPE_PROFILE = 10,
  TYPE_VIDEO = 20,
  TYPE_ARENA_ROOM = 30,
  TYPE_REFERRAL = 40,
  TYPE_TRIVIA_WINNERS = 50,
  TYPE_CLIP = 80,
  TYPE_GENERIC = 60,
  // This is to be used when prefix is not needed
  TYPE_GENERIC_2 = 70,
} // Added for backward compatibility as frontend used these already

export interface TopGameInterface {
  uid: string;
  name: string;
  description: string;
  cover_url: string;
  cover_url_square: string;
  background_url: string;
  category: string;
  is_active: number;
  tags: string[];
  followers_count: number;
  created_at: number;
  total_views_count: number;
  viewersCurrent: number;
  is_following: boolean;
}
export interface GameInterface {
  uid: string;
  label: string;
  category: string;
  color: string;
  image_url: string;
  current_views: number;
  total_views: number;
  followers_count: number;
  is_live: boolean;
}
export interface StreamInterface {
  uid: string;
  VODUrl: string;
  VODUrlV2: string;
  ad_config: {
    enabled: boolean;
    force_skip_delay: boolean;
    skip_delay: number;
    pre_roll: {
      instances: number;
      maxDuration: number;
    };
  };
  followers_count: number;
  akamai_live_playback_url: string;
  alias: string;
  block_status: number;
  can_battleup: boolean;
  categories: {
    uid: string;
    label: string;
    category: string;
    color: string;
  }[];
  chat_config: {
    slow_mode_time: number;
    chat_cost: number;
    chat_mode: number;
    sticker_only_chat_ends_at: number;
  };
  comments_count: number;
  conf: {
    hls: string;
    hlsProtected: string;
    rewindURLProtected: string;
  };
  created_at: number;
  currrentViews: number;
  description: string;
  downloadLink: string;
  download_status: number;
  duration: number;
  flag_count: number;
  game: {
    uid: string;
    name: string;
  };
  game_color: string;
  game_name: string;
  game_uid: string;
  is_active: boolean;
  is_uploaded: boolean;
  is_vod_available: boolean;
  likes: number;
  more_details: {
    streamer_type: number;
    is_verified_streamer: boolean;
  };
  msl4_publish_url: string;
  priority_flag_count: number;
  show_leaderboard: boolean;
  started_at: number;
  status: number;
  streamer: {
    user_uid: string;
    username: string;
    avatar_url: string;
    bio: string;
    full_name: string;
    is_loco_verified: boolean;
    user_type: number;
  };
  tags: string[];
  thumbnail_url_small: string;
  title: string;
  total_live_views_count: number;
  total_live_watch_count: number;
  total_peak_views_count: number;
  total_views: number;
  total_vod_views_count: number;
  total_vod_watch_count: number;
  updated_at: number;
  user_uid: string;
  viewersCurrent: number;
  visibility: number;
  vod_duration: 0;
  vod_enabled: boolean;
  vod_url: string;
  is_vip?: boolean;
  trick_play_status?: number;
  trick_play_url?: string;
  stream_infra_type?: number; // 10-> default , 20-> live stream is from IVS
}

export enum STREAMTYPE {
  LIVE = '20',
  VOD = '30',
  VOD_COMMENTS = '40',
}
export interface StreamerInterface {
  user_uid: string;
  followers_count: number;
  followees_count: number;
  categories_following_count: number;
  views_count: number;
  streams_count: number;
  watch_count: number;
  profile_tags: any[];
  is_live: boolean;
  primary_game: string;
  total_stream_views_count: number;
  username: string;
  bio: string;
  avatar_url: string;
  full_name: string;
  gender: number;
  dob: string;
  user_type: number;
  can_stream: number;
  is_loco_verified: boolean;
  primary_category: string;
  current_views: number;
  total_views: number;
  is_active: boolean;
  is_following: boolean;
}
export interface StreamerProfileApiInterface {
  user_uid: string;
  created_at: string;
  updated_at: string;
  followers_count: number;
  followees_count: number;
  categories_following_count: number;
  views_count: number;
  streams_count: number;
  watch_count: number;
  profile_tags: {
    tag_uid: string;
    tag_type: number;
    display_name: string;
    created_at: number;
    updated_at: number;
    is_active: string;
  }[];
  is_live: boolean;
  primary_game: any;
  total_stream_views_count: number;
  is_sip_agreement_accept: boolean;
  moengage_migrated_status: number;
  discord_webhook: string;
  username: string;
  bio: string;
  avatar_url: string;
  full_name: string;
  gender: number;
  dob: string;
  user_type: number;
  can_stream: number;
  is_loco_verified: boolean;
  is_fake: boolean;
  is_following: boolean;
  is_streamer_blocked: boolean;
  permissions: {
    show_following: number;
    show_past_streams: number;
    show_leaderboard: number;
    is_clip_upload_enabled: false;
  };
}
export interface HomeFeedInterface {
  isEnd: boolean;
  version: number;
  recipe_uid: string;
  recipe_name: string;
  results: [HomeFeedResultInterface];
}
export interface FeatureVideoComponentInterface {
  avatar: string;
  avatar_label: string;
  deep_link: string;
  image_url: string;
  stream_uid: string;
  stream_url: string;
  title: string;
  force_autoplay: boolean;
  is_live: boolean;
  is_loco_verified: boolean;
  playback_url: {
    playback: string;
    rewind: string;
  };
  order: number;
  view_count_delay: number;
  viewers: number;
}
export interface HomeFeedResultInterface {
  row_type: string;
  section_uid: string;
  title: string;
  is_vertical_section: boolean;
  highlightColor: string;
  thumbnail: string;
  more_data: {
    has_more_data: boolean;
    name: string;
    click_action: {
      deep_link: string;
    };
  };
  banner_content: [FeatureVideoComponentInterface];
  content: {
    component: VideoComponentInterface | StreamerComponentInterface | CategoryComponentInterface | BannerComponentInterface;
  }[];
}
export enum FEEDCOMPONENTTYPE {
  FULL_WIDTH_VOD_OR_LIVE = 'FULL_WIDTH_VOD_OR_LIVE',
  STREAMERS = 'STREAMERS',
  MEDIUM_WIDTH_VOD = 'MEDIUM_WIDTH_VOD',
  CATEGORY_FULL_WIDTH = 'CATEGORY_FULL_WIDTH',
  ANNOUNCEMENT_BIG = 'ANNOUNCEMENT_BIG',
  ANNOUNCEMENT_SMALL = 'ANNOUNCEMENT_SMALL',
  CAROUSEL = 'CAROUSEL_VOD',
}
export enum FEEDCOMPONENTID {
  ANNOUNCEMENT_BIG = 7,
  ANNOUNCEMENT_SMALL = 8,
}
export interface CategoryFeedResults {
  row_type: string;
  section_uid: string;
  title: string;
  is_vertical_section: boolean;
  more_data: {
    has_more_data: boolean;
    name: string;
    click_action: {
      deep_link: string;
    };
  };
  content: {
    component: CategoryComponentInterface;
  }[];
}
export interface CategoryFeedInterface {
  isEnd: boolean;
  version: number;
  recipe_uid: string;
  recipe_name: string;
  nextOffset: string;
  next: boolean;
  results: CategoryFeedResults[];
}
export interface HomeComponentInterface {
  id: number;
  name: string;
  component_uid: string;
  analytics: {
    ingredient_name: string;
    ingredient_index: number;
    ingredient_type: string;
    thumbnail: string;
    _dt_: string;
    section_index: string;
    section_title: string;
    cta_text: string;
    type: string;
    stream_uid: string;
    streamer_id: string;
    streamer_name: string;
    category_name: string;
    category_id: string;
    stream_status: string;
    streamer_type: string;
    _pk_: string;
  };
  data: {
    stream_uid: {
      data: string;
    };
    thumbnail: {
      data: string;
    };
    streamer_name: {
      data: string;
      click_action: {
        deep_link: string;
      };
    };
    streamer_uid: {
      data: string;
    };
    streamer_image: {
      data: string;
      click_action: {
        deep_link: string;
      };
    };
    duration: {
      data: number;
    };
    stream_title: {
      data: string;
    };
    verified_streamer: boolean;
    uploaded_time: {
      data: number;
    };
    is_live: boolean;
    stream_tags: [{
      data: string;
      click_action: {
        deep_link: string;
      };
    }];
    click_action: {
      deep_link: string;
    };
    view_count: {
      data: string;
    };
  };
}
export interface CategoryComponentInterface {
  id: number;
  name: string;
  component_uid: string;
  analytics: {
    ingredient_name: string;
    ingredient_index: number;
    ingredient_type: string;
    thumbnail: string;
    _dt_: string;
    section_index: string;
    section_title: string;
    cta_text: string;
    followed: string;
    type: string;
    _pk_: string;
  };
  data: {
    title: {
      data: string;
    };
    thumbnail: {
      data: string;
      click_action: {
        deep_link: string;
      };
    };
    sub_title: {
      data: string;
    };
    followers_count: {
      data: string;
    };
    views_count: {
      data: string;
    };
    cta_text: {
      data: string;
      click_action: {
        deep_link: string;
      };
    };
    is_following: boolean;
    game_uid: {
      data: string;
    };
    is_live: boolean;
    click_action: {
      deep_link: string;
    };
  };
}
export interface BannerComponentInterface {
  id: number;
  name: string;
  component_uid: string;
  analytics: {
    ingredient_name: string;
    ingredient_index: number;
    ingredient_type: string;
    thumbnail: string;
    _dt_: string;
    section_index: string;
    section_title: string;
    cta_text: string;
    followed: string;
    type: string;
    _pk_: string;
  };
  data: {
    thumbnail: {
      data: string;
      open_new_tab: boolean;
      click_action: {
        deep_link: string;
      };
    };
    thumbnail_mobile: {
      data: string;
      open_new_tab: boolean;
      click_action: {
        deep_link: string;
      };
    };
  };
}
export interface StreamerFeedResults {
  row_type: string;
  section_uid: string;
  title: string;
  is_vertical_section: boolean;
  more_data: {
    has_more_data: boolean;
    name: string;
    click_action: {
      deep_link: string;
    };
  };
  content: {
    component: StreamerComponentInterface;
  }[];
}
export interface StreamersFeedInterface {
  isEnd: boolean;
  version: number;
  recipe_uid: string;
  recipe_name: string;
  results: StreamerFeedResults[];
}
export interface StreamerComponentInterface {
  id: number;
  name: string;
  component_uid: string;
  analytics: {
    ingredient_name: string;
    ingredient_index: number;
    ingredient_type: string;
    thumbnail: string;
    _dt_: string;
    section_index: string;
    section_title: string;
    show_cta_follow: string;
    type: string;
    streamer_id: string;
    streamer_name: string;
    streamer_type: string;
    _pk_: string;
  };
  data: {
    streamer_name: {
      data: string;
    };
    streamer_image: {
      data: string;
    };
    followers_count: {
      data: number;
    };
    views_count: {
      data: 166584;
    };
    is_following: boolean;
    show_cta_follow: boolean;
    is_live: boolean;
    verified_streamer: boolean;
    user_type: number;
    user_uid: {
      data: string;
    };
    click_action: {
      deep_link: string;
    };
  };
}
export interface VideoComponentInterface {
  id: number;
  name: string;
  component_uid: string;
  isFeatureVideo?: boolean;
  featureVideoData?: FeatureVideoComponentInterface;
  analytics: {
    ingredient_name: string;
    ingredient_index: number;
    ingredient_type: string;
    thumbnail: string;
    _dt_: string;
    section_index: string;
    section_title: string;
    cta_text: string;
    type: string;
    stream_uid: string;
    streamer_id: string;
    streamer_name: string;
    category_name: string;
    category_id: string;
    stream_status: string;
    streamer_type: string;
    _pk_: string;
  };
  data: {
    stream_uid: {
      data: string;
    };
    thumbnail: {
      data: string;
      open_new_tab?: boolean;
      click_action?: {
        deep_link: string;
      };
    };
    streamer_name: {
      data: string;
      click_action: {
        deep_link: string;
      };
    };
    streamer_uid: {
      data: string;
    };
    streamer_image: {
      data: string;
      click_action: {
        deep_link: string;
      };
    };
    duration: {
      data: number;
    };
    stream_title: {
      data: string;
    };
    verified_streamer: boolean;
    uploaded_time: {
      data: number;
    };
    is_live: boolean;
    stream_tags: [{
      data: string;
      click_action: {
        deep_link: string;
      };
    }];
    click_action: {
      deep_link: string;
    };
    view_count: {
      data: '4.4K Views';
    };
    playback_url: {
      playback: string;
    };
  };
}

// export interface BannerComponentInterface {
//   order: number
//   stream_uid: string
//   image_url: string
//   stream_url: string
//   avatar: string
//   title: string
//   viewers: number
//   is_live: boolean
//   avatar_label: string
//   deep_link: string
//   is_loco_verified: boolean
// }

export interface TrendingFeedInterface {
  isEnd: boolean;
  version: number;
  recipe_uid: string;
  recipe_name: string;
  results: [{
    row_type: string;
    section_uid: string;
    title: string;
    is_vertical_section: boolean;
    more_data: {
      has_more_data: boolean;
      name: string;
      click_action: {
        deep_link: string;
      };
    };
    content: {
      component: VideoComponentInterface;
    }[];
  }, {
    row_type: string;
    section_uid: string;
    title: string;
    is_vertical_section: boolean;
    more_data: {
      has_more_data: boolean;
      name: string;
      click_action: {
        deep_link: string;
      };
    };
    content: {
      component: VideoComponentInterface;
    }[];
  }, {
    row_type: string;
    section_uid: string;
    title: string;
    is_vertical_section: boolean;
    more_data: {
      has_more_data: boolean;
      name: string;
      click_action: {
        deep_link: string;
      };
    };
    content: {
      component: StreamerComponentInterface;
    }[];
  }];
}
export interface VideosFeedResults {
  row_type: string;
  section_uid: string;
  title: string;
  is_vertical_section: boolean;
  more_data: {
    has_more_data: boolean;
    name: string;
    click_action: {
      deep_link: string;
    };
  };
  content: {
    component: VideoComponentInterface;
  }[];
}
export interface VideosFeedInterface {
  isEnd: boolean;
  version: number;
  recipe_uid: string;
  recipe_name: string;
  results: [VideosFeedResults];
  nextOffset: number;
  next: string;
}
export interface gameStreamsResponseInterface {
  count: number;
  next: string | null;
  previous: string | null;
  results: StreamInterface[];
}
export interface CommentsInterface {
  author: {
    avatar_url: string;
    user_uid: string;
    username: string;
  };
  created_at: number;
  is_deleted: boolean;
  message: string;
  replies_count: number;
  stream_uid: string;
  uid: string;
  updated_at: number;
}
export type GameIds = {
  game_id: string;
  game_name: string;
  game_user_uid: string;
};
export interface MeInterface {
  avatar: string;
  avatar_url: string;
  bio: string;
  can_stream: number;
  categories_following_count: number;
  created_at: number;
  dob: string;
  email?: string;
  followees_count: number;
  followers_count: number;
  full_name: string;
  game_ids: GameIds[];
  gender: number;
  is_fake: boolean;
  is_live: boolean;
  is_loco_verified: boolean;
  is_sip_agreement_accept: boolean;
  is_clip_upload_enabled: boolean;
  primary_game: string;
  profile_tags: {
    tag_uid: string;
    tag_type: number;
    is_active: boolean;
    display_name: string;
    created_at: number;
    updated_at: number;
  }[];
  streams_count: number;
  total_stream_views_count: number;
  updated_at: number;
  user_type: number;
  user_uid: string;
  username: string;
  views_count: number;
  watch_count: number;
  social_link_info: {
    apple?: string;
    fb?: null;
    gmail?: string;
  };
  phone?: string;
  is_username_changed?: boolean;
  phone_code?: string;
}
export interface SidebarFeedInterface {
  isEnd: boolean;
  version: number;
  recipe_uid: string;
  recipe_name: string;
  results: [SidebarResultInterface];
}
export interface SidebarResultInterface {
  row_type: number;
  section_uid: string;
  is_vertical_section: boolean;
  content: SidebarStreamInterface[];
}
export interface SidebarStreamInterface {
  component: {
    id: number;
    name: string;
    component_uid: string;
    analytics: {
      ingredient_index: number;
      ingredient_cache_name: string;
      ingredient_name: string;
      thumbnail: string;
      stream_uid: string;
      streamer_id: string;
      streamer_name: string;
      category_name: string;
      category_id: string;
      stream_status: string;
      streamer_type: string;
    };
    data: {
      stream_uid: {
        data: string;
      };
      thumbnail: {
        data: string;
        click_action?: {
          deep_link: string;
        };
      };
      streamer_name: {
        data: string;
      };
      streamer_uid: {
        data: string;
      };
      streamer_image: {
        data: string;
      };
      duration: {
        data: number;
      };
      stream_title: {
        data: string;
      };
      verified_streamer: boolean;
      uploaded_time: {
        data: number;
      };
      is_live: boolean;
      view_count: {
        data: string;
      };
      playback_url: {
        playback: string;
      };
    };
  };
}
export interface StreamerVideos {
  allVODS: StreamInterface[];
  liveStream?: StreamInterface | null;
  videoNext: any;
  videosCount: any;
}