function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useDeviceInfo } from '@hooks/react-use';
import LogEvents from '@layouts/stream/LogEvents';
import { PAGES_ROUTE } from '@modules/analytics/types/constants';
import { IS_HIDE_AD_IN_PIP, useAdsStore } from '@stores/adsStore';
import { useStreamStore } from '@stores/streamStore';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { ControlSheetSection, InitializeVideoPlayer, KeyboardShortcuts, PlayerSpinner, VideoElements } from './Components';
import PlayerEventsComp from './Components/PlayerEventsComp';
import StreamDetailsForFullScreen from './Components/StreamDetailsForFullScreen';
import StreamEndedOutside from './Components/StreamEndedOutside';
import { useControlDisplayStore } from './store/useControlDisplayStore';
import { usePlayerStore } from './store/usePlayerStore';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsHoverOnContainer,
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
const {
  setIsPortalOpen,
  setPlayerProps
} = usePlayerStore.getState();
const {
  setStream
} = useStreamStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "eivff4",
  styles: "display:none"
} : {
  name: "1ir7tb7-IVSVideoPlayer",
  styles: "display:none;label:IVSVideoPlayer;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const IVSVideoPlayer = () => {
  const router = useRouter();
  const {
    screen_isMobile,
    screen_isDesktop
  } = useDeviceInfo();
  const currentPathname = router.pathname;
  const {
    theatreMode,
    isFullScreen
  } = useStreamStore(state => ({
    isFullScreen: state.isFullScreen,
    theatreMode: state.theatreMode
  }));
  const isEnded = usePlayerStore(state => state.isEnded);
  const streamUid = usePlayerStore(state => state.playerProps.stream?.uid || null);
  const previousPathname = usePrevious(currentPathname);
  const previousStreamUid = usePrevious(streamUid);
  const isPathChanged = currentPathname !== previousPathname;
  const isStreamChanged = previousStreamUid !== streamUid;
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);

  /*
   * if user is on mobile and goes page to any page from stream page
   * if user goes from /stream to /clips then close pip and hit unicorn api
   */

  useEffect(() => {
    const isPlayerStreamPresent = !!streamUid;
    if (!isPlayerStreamPresent || !previousPathname || !(isPathChanged || isStreamChanged)) return;
    const isStreamPage = currentPathname === PAGES_ROUTE.STREAM_PAGE;
    const isClipPage = currentPathname === PAGES_ROUTE.CLIPS_PAGE || currentPathname === '/clips';
    const isStreamerProgilePage = currentPathname === PAGES_ROUTE.STREAMER_PAGE;
    const isAdPresent = !!useAdsStore.getState().ad?.id;
    if ((isClipPage || isAdPresent && IS_HIDE_AD_IN_PIP) && isPortalOpen) {
      // closes the portal when a clip page or ad is present and the portal is open.
      setStream(undefined);
      setIsPortalOpen(false);
      setPlayerProps(null);
      return;
    }
    if (isPathChanged && !isClipPage && !isStreamPage) {
      //   // Open player in portal
      setIsPortalOpen(true);
    } else if (!isPortalOpen && !(isStreamPage || isStreamerProgilePage)) {
      // Portal already closed on some other page
      setPlayerProps(null);
      // Unset stream when user go to new/another page
      setStream(undefined);
    }
  }, [screen_isMobile, currentPathname, isPathChanged, isPortalOpen, isStreamChanged, previousPathname, streamUid]);
  if (!streamUid) {
    return _jsxs(_Fragment, {
      children: [_jsx(PlayerSpinner, {}), _jsx("p", {
        css: _ref,
        children: "Something went wrong 00123"
      })]
    });
  }
  if (isEnded) {
    return _jsx(_Fragment, {
      children: _jsx(StreamEndedOutside, {})
    });
  }
  return _jsx(React.Fragment, {
    children: _jsx("div", {
      className: "player-section",
      children: _jsx("div", {
        className: "video-container",
        onMouseMove: () => {
          if (!theatreMode && !isFullScreen) {
            if (screen_isDesktop) {
              setIsHoverOnContainer(true);
            } else {
              resetControlSectionVisiblity();
            }
          } else {
            // For theatre mode & full screen mode
            resetControlSectionVisiblity();
          }
        },
        onMouseLeave: () => setIsHoverOnContainer(false),
        children: _jsx(InitializeVideoPlayer, {
          children: _jsxs(ControlSheetSection, {
            children: [!isPortalOpen && _jsx(StreamDetailsForFullScreen, {}), _jsx(KeyboardShortcuts, {}), _jsx(VideoElements, {}), _jsxs(_Fragment, {
              children: [_jsx(PlayerEventsComp, {}), _jsx(LogEvents, {})]
            })]
          })
        })
      })
    })
  });
};
export default IVSVideoPlayer;