import * as amplitude from '@amplitude/analytics-browser';
import { LogLevel } from '@amplitude/analytics-types';
import { userAgentEnrichmentPlugin } from '@amplitude/plugin-user-agent-enrichment-browser';
import { IEventProps } from './types/eventPropsTypes';
const DEFAULT_PARAMS = {
  logLevel: process.env.NEXT_PUBLIC_APP_STAGE === 'production' ? LogLevel.None : LogLevel.Warn,
  cookieOptions: {
    expiration: 365
  },
  // Expires in 1 year (would fallback to 10 years by default, which isn't GDPR compliant),
  attribution: {
    disabled: true
  },
  defaultTracking: {
    formInteractions: false,
    pageViews: false,
    sessions: false
  }
};
export class Amplitude_v2 {
  amplitude = amplitude;
  initialized = false;
  constructor(params: {
    key: string;
  }) {
    const AMPLITUDE_API_KEY = params?.key;
    if (!process.browser || !AMPLITUDE_API_KEY) {
      this.initialized = false;
      return;
    }
    const uaPlugin = userAgentEnrichmentPlugin({
      osName: true,
      osVersion: true,
      deviceManufacturer: true,
      deviceModel: true
    });
    amplitude?.add(uaPlugin);
    amplitude?.init(AMPLITUDE_API_KEY, undefined, DEFAULT_PARAMS);
    this.amplitude = amplitude;
    this.initialized = !!AMPLITUDE_API_KEY;
  }
  setUserId = (params: null | string | undefined) => {
    this.amplitude.setUserId(params || undefined);
  };
  setAmplitudeIdentity = (identityObj: IEventProps) => {
    if (!identityObj) {
      return;
    }
    const identify = new amplitude.Identify();
    const Properties = Object.entries(identityObj);
    for (const data of Properties) {
      const [key, value] = data;
      identify.set(key, value);
    }
    this.amplitude?.identify(identify);
  };
  addAmplitudeIdentity = (identityObj: IEventProps) => {
    if (!identityObj) {
      return;
    }
    const identify = new amplitude.Identify();
    const Properties = Object.entries(identityObj);
    for (const data of Properties) {
      const [key, value] = data;
      identify.add(key, value);
    }
    this.amplitude?.identify(identify);
  };
  setUserProperties = (identityObj: IEventProps) => {
    return this.setAmplitudeIdentity(identityObj);
  };
  logEvent = async (eventName: string, properties: IEventProps) => {
    this.amplitude?.track(eventName, {
      ...properties
    });
    if (typeof window !== 'undefined' && window?.dataLayer) {
      window?.dataLayer?.push({
        event: eventName,
        ...properties
      });
    }
  };
}