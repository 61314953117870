import { useAppStore } from '@stores/appStore';
import { browserDetailDelection } from './browserDetailDetection';
interface NavigatorExtended extends Navigator {
  getBattery?: () => Promise<any>;
  connection: any;
}
export interface DeviceInfo {
  network_type?: string;
  is_charging?: boolean;
  manufacturer?: string;
  network_speed_kbps?: number;
  os_name?: string;
  os_version?: string;
  timezone_offset?: string;
  screen?: string;
  browser?: string;
  browserVersion?: string;
  browserMajorVersion?: number;
  cookies?: boolean;
}
export async function getDeviceInfo(): Promise<DeviceInfo> {
  const deviceInfo: DeviceInfo = {};

  // Check network type
  if (navigator && (navigator as NavigatorExtended).connection) {
    deviceInfo.network_type = (navigator as NavigatorExtended).connection.type;
  }

  // Check charging status
  if (navigator && (navigator as NavigatorExtended).getBattery) {
    //@ts-ignore
    const battery = await (navigator as NavigatorExtended)?.getBattery();
    deviceInfo.is_charging = battery.charging;
  }

  // Check manufacturer
  if (navigator && navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad') || navigator.userAgent.includes('iPod')) {
    deviceInfo.manufacturer = 'Apple';
  } else if (navigator && navigator.userAgent.includes('Android')) {
    deviceInfo.manufacturer = 'Android';
  }
  const timezoneOffset = getTimeZone();
  deviceInfo.timezone_offset = `GMT${timezoneOffset}`;
  const browser_details = browserDetailDelection();
  deviceInfo.os_name = browser_details.os;
  deviceInfo.os_version = browser_details.osVersion;
  deviceInfo.screen = browser_details.screen;
  deviceInfo.browser = browser_details.browser;
  deviceInfo.browserVersion = browser_details.browserVersion;
  deviceInfo.browserMajorVersion = browser_details.browserMajorVersion;
  deviceInfo.cookies = browser_details.cookies;
  useAppStore.getState().setDeviceInfo(deviceInfo);

  // Setting Network Speed in Last
  await new Promise(res => {
    const low_speed_timer = setTimeout(() => {
      // Resolve, if unable to download image in 2 seconds
      res('');
    }, 2000);

    // Check network speed
    const image = new Image();
    const startTime = Date.now();
    image.onload = function () {
      clearTimeout(low_speed_timer);
      const endTime = Date.now();
      const duration = endTime - startTime;
      const speedKbps = Math.round(75.2 / (duration / 1000)); // 75kb image size
      deviceInfo.network_speed_kbps = speedKbps;
      res('');
    };
    image.onerror = function () {
      clearTimeout(low_speed_timer);
      res('');
    };
    image.src = 'https://loco.gg/' + 'static/images/login-bg.jpg?t=' + startTime + Math.random(); // to avoid caching
  });

  return deviceInfo;
}
function getTimeZone() {
  const offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (offset < 0 ? '+' : '-') + ('00' + Math.floor(o / 60)).slice(-2) + ':' + ('00' + o % 60).slice(-2);
}