import { StreamInterface } from '@customTypes/index';
import { IS_HIDE_AD_IN_PIP, useAdsStore } from '@stores/adsStore';
import { useStreamStore } from '@stores/streamStore';
import type { PlayerError as IPlayerError, PlayerState as IPlayerState } from 'amazon-ivs-player';
import { PlayerState } from 'amazon-ivs-player';
import create, { GetState, SetState } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';
import { PLAYER_TYPE } from '../constants';
import { useControlDisplayStore } from './useControlDisplayStore';
const PERSIST_STORAGE_CONFIG: PersistOptions<IVideoPlayerStore> = {
  name: 'video-player-loco',
  getStorage: () => window.localStorage,
  partialize: state => ({
    isMuted: state.isMuted,
    volume: state.volume
  })
};
export interface IPlayerProps {
  stream: null | Pick<StreamInterface, 'uid' | 'status' | 'duration' | 'started_at' | 'thumbnail_url_small' | 'stream_infra_type'>;
  playbackUrl: string | null;
  vodPlaybackUrl: string | null;
}
export interface ReportActionsInterface {
  uid: string;
  name: string;
}
interface IVideoPlayerStore {
  isPortalOpen: boolean;
  setIsPortalOpen: (isPortalOpen: boolean, handleClosePlayer?: boolean) => void;
  isMiniPlayerEnabled: boolean;
  setIsMiniPlayerEnabled: (isMiniPlayerEnabled: boolean) => void;
  isLoading: boolean;
  setIsLoading(isLoading: boolean): void;

  // Structure States
  playerError: null | IPlayerError;
  setPlayerError(playerError: null | IPlayerError): void;
  playerProps: IPlayerProps;
  setPlayerProps(playerProps: Partial<IPlayerProps> | null): void;
  activePlayerType: PLAYER_TYPE;
  setActivePlayerType(activePlayerType: PLAYER_TYPE): void;
  isVodPlayerDisabled: boolean;
  setIsVodPlayerDisabled: (value: boolean) => void;
  isEnded: boolean;
  setIsEnded: (value: boolean) => void;
  isMuted: boolean;
  setIsMuted: (value: boolean) => void;
  volume: number;
  setVolume: (value: number) => void;
  playerState: IPlayerState;
  setPlayerState: (playerState: IPlayerState) => void;
  playerStateLogs: string[];
  resetPlayerLogs(): void;
  setPlayerStateLogs(playerStateLogs: string): void;
  playerDuration: number;
  setPlayerDuration(playerDuration: number): void;
  playerPosition: number;
  setPlayerPosition(playerPosition: number): void;
  seekbarPosition: number;
  setSeekbarPosition(seekbarPosition: number): void;
  selectedReportAction: null | string;
  setSelectedReportAction: (selectedReportAction: null | string) => void;
  reportActions: null | ReportActionsInterface[];
  setReportActions: (reportActions: null | ReportActionsInterface[]) => void;
}
const IVSPlayerStore = (set: SetState<IVideoPlayerStore>, get: GetState<IVideoPlayerStore>): IVideoPlayerStore => ({
  isLoading: true,
  setIsLoading: isLoading => set({
    isLoading
  }),
  playerProps: {
    playbackUrl: null,
    vodPlaybackUrl: null,
    stream: null
  },
  setPlayerProps: playerProps => {
    if (!playerProps) {
      set({
        playerProps: {
          playbackUrl: null,
          vodPlaybackUrl: null,
          stream: null
        }
      });
    } else {
      const oldPlayerProps = get().playerProps;
      const newProps = {
        ...oldPlayerProps,
        ...playerProps
      };
      set({
        playerProps: newProps,
        // Instantly set other new values
        activePlayerType: newProps.stream?.status === 20 ? PLAYER_TYPE.LIVE : PLAYER_TYPE.VOD
      });
    }
  },
  activePlayerType: PLAYER_TYPE.LIVE,
  setActivePlayerType: activePlayerType => set({
    activePlayerType
  }),
  isMiniPlayerEnabled: true,
  // Enabled by default
  setIsMiniPlayerEnabled: isMiniPlayerEnabled => {
    if (IS_HIDE_AD_IN_PIP) {
      // if ad is there then don't allow pipmode
      const ad = useAdsStore.getState().ad;
      if (ad) {
        set({
          isMiniPlayerEnabled: false,
          isPortalOpen: false
        });
        return;
      }
    }
    if (isMiniPlayerEnabled) {
      set({
        isMiniPlayerEnabled: true
      });
    } else {
      // Close portal, if miniplayer is disabled
      set({
        isMiniPlayerEnabled: false,
        isPortalOpen: false
      });
    }
  },
  isPortalOpen: false,
  setIsPortalOpen: (isPortalOpen, handleClosePlayer) => {
    const isMiniPlayerEnabled = get().isMiniPlayerEnabled;
    const isMobileView = useControlDisplayStore.getState().isMobileView;
    if (isMobileView) {
      // Close player on mobile
      set({
        isPortalOpen: false
      });
      if (typeof window !== 'undefined') {
        const currentPage = window.location.pathname;
        if (!currentPage.includes('/stream/') && !currentPage.includes('/streamers/')) {
          get().setPlayerProps(null);
        }
      }
      return;
    }
    if (isPortalOpen) {
      useStreamStore.getState().setIsFullScreen(false);
      useStreamStore.getState().toggleTheatreMode(false);
    }
    const {
      isEnded,
      playerError
    } = usePlayerStore.getState();
    const isHideMiniPlayer = !isMiniPlayerEnabled || !!playerError || isEnded;
    // Close portal, if miniplayer is disabled
    set({
      isPortalOpen: isHideMiniPlayer ? false : isPortalOpen
    });
    if (handleClosePlayer && !isPortalOpen && typeof window !== 'undefined') {
      const currentPage = window.location.pathname;
      if (!currentPage.includes('/stream/') && !currentPage.includes('/streamers/')) {
        get().setPlayerProps(null);
      }
    }
  },
  isVodPlayerDisabled: true,
  setIsVodPlayerDisabled: isVodPlayerDisabled => set({
    isVodPlayerDisabled
  }),
  isEnded: false,
  setIsEnded: isEnded => set({
    isEnded
  }),
  playerError: null,
  setPlayerError: playerError => set({
    playerError
  }),
  isMuted: false,
  setIsMuted: isMuted => {
    const volume = get().volume;
    let n_volume = volume;
    if (!isMuted && volume === 0) {
      // unmute with volume 0, increase volume little bit
      n_volume = 0.5;
    }
    set({
      isMuted,
      volume: n_volume
    });
  },
  volume: 1,
  setVolume: volume => {
    if (volume < 0) {
      volume = 0;
    } else if (volume > 1) {
      volume = 1;
    }
    volume = Math.round(volume * 100) / 100;
    set({
      volume,
      isMuted: volume === 0
    });
  },
  playerDuration: 0,
  setPlayerDuration: playerDuration => set({
    playerDuration
  }),
  playerState: PlayerState.IDLE,
  setPlayerState: playerState => set({
    playerState
  }),
  playerStateLogs: [],
  resetPlayerLogs: () => {
    set({
      playerStateLogs: []
    });
  },
  setPlayerStateLogs: newState => {
    const currentPlayerState = get().playerStateLogs || [];
    set({
      playerStateLogs: [...currentPlayerState, newState]
    });
  },
  playerPosition: 0,
  setPlayerPosition: playerPosition => set({
    playerPosition
  }),
  seekbarPosition: 0,
  setSeekbarPosition: seekbarPosition => set({
    seekbarPosition
  }),
  selectedReportAction: null,
  setSelectedReportAction: selectedReportAction => set({
    selectedReportAction
  }),
  reportActions: null,
  setReportActions: reportActions => set({
    reportActions
  })
});
export const usePlayerStore = create<IVideoPlayerStore>(persist(IVSPlayerStore, PERSIST_STORAGE_CONFIG));