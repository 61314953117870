import { getCookie } from '@utils/cookies';
import queryString from 'query-string';
import { converStreamerIdIntoUsername } from '../../data/streamerUsernames';
import { getShortUrl } from '../../functions/service';
import { IPageInfo } from '../../stores/appStore';
import { MeInterface, UrlType } from '../../types';
type QueryType = {
  [key: string]: string | boolean;
};
type PropsType = {
  query: QueryType;
  deepLinkRID: string;
  urlType: UrlType;
  appsFlyerLink?: string;
  isForwardParams?: boolean;
};
const APPSFLYERS = 'https://loco.onelink.me/65Kg/WebShare';
async function ShortUrlGetter({
  query,
  deepLinkRID,
  urlType,
  appsFlyerLink,
  isForwardParams = true
}: PropsType) {
  const getLocoNowUrl = queryString.stringifyUrl({
    url: 'https://getloconow.com/client',
    query: {
      ...query
    }
  });
  const updateQuery = isForwardParams ? {
    deep_link_value: JSON.stringify({
      dl: encodeURIComponent(getLocoNowUrl),
      rid: deepLinkRID
    }),
    _forward_params: 1,
    ...query
  } : {
    deep_link_value: JSON.stringify({
      dl: encodeURIComponent(getLocoNowUrl),
      rid: deepLinkRID
    }),
    ...query
  };
  const longFormUrl = queryString.stringifyUrl({
    url: appsFlyerLink ? appsFlyerLink : APPSFLYERS,
    query: updateQuery
  });
  const params = {
    long_url: longFormUrl,
    url_type: urlType
  };
  const Authorization = getCookie('access_token');
  const {
    short_url
  } = await getShortUrl({
    token: Authorization!,
    urlObj: params
  });
  return short_url;
}
export const appsFlyersDownloadAttributionLink = async (donwloadLink: string, pageInfo: IPageInfo, me: MeInterface | null, queryParams?: {
  [key: string]: string;
}) => {
  const isStreamerPage = pageInfo.page === 'webpage_streamer';
  const isStreamPage = pageInfo.page === 'webpage_stream';
  const isVodPage = pageInfo.page === 'webpage_vod';
  const isCategoryPage = pageInfo.page === 'webpage_category';
  const isCategoriesPage = pageInfo.page === 'webpage_categories';
  const isPageInfoContainStreamerInfo = isStreamerPage || isStreamPage || isVodPage;
  const isPageInfoContainCategoryInfo = isCategoryPage;
  const c = pageInfo.page;
  // Adset and Adset ID to be used for user properties to understand who is sharing the content.
  const af_adset_id = me?.user_uid ? me.user_uid : 'web_no_login';
  const af_adset = me?.username ? me.username : 'web_no_login';

  // af_ad/af_ad_id is use to map streamer
  const af_ad_id = isPageInfoContainStreamerInfo ? pageInfo.streamerId || pageInfo.page : isPageInfoContainCategoryInfo ? pageInfo.categoryId || pageInfo.page : pageInfo.page;
  const af_ad = isPageInfoContainStreamerInfo ? pageInfo.streamerName || pageInfo.page : isPageInfoContainCategoryInfo ? pageInfo.categoryName || pageInfo.page : pageInfo.page;

  // Ad type for mapping type of content being shared
  const af_ad_type = isStreamPage ? 'stream' : isVodPage ? 'vod' : isStreamerPage ? 'profile' : 'webpage';

  // Channel, site ID, and subsite ID can be used to map category, videoID, and category ID.
  const af_channel = (isStreamPage || isVodPage) && pageInfo?.categoryName ? pageInfo.categoryName : 'webpage';
  const af_siteid = (isStreamPage || isVodPage) && pageInfo?.videoId ? pageInfo.videoId : 'webpage';
  const af_sub_siteid = (isStreamPage || isVodPage) && pageInfo?.categoryId ? pageInfo.categoryId : 'webpage';
  let query: any = {
    c,
    af_adset,
    af_adset_id,
    af_ad,
    af_ad_id,
    af_ad_type,
    af_channel,
    af_siteid,
    af_sub_siteid
  };
  if (queryParams) {
    Object.assign(query, queryParams);
  }
  const deepLinkRID = me?.user_uid ? me.user_uid : '';
  let urlType = UrlType.TYPE_GENERIC;
  if (isStreamerPage && pageInfo.streamerId) {
    query = {
      ...query,
      type: '4',
      streamer_uid: pageInfo.streamerId,
      play_latest: true,
      af_web_dp: `https://loco.gg/streamers/${converStreamerIdIntoUsername(pageInfo.streamerId)}`
    };
    urlType = UrlType.TYPE_PROFILE;
  }
  if ((isVodPage || isStreamPage) && pageInfo?.videoId && pageInfo.streamerId) {
    query = {
      ...query,
      type: '5',
      stream_uid: pageInfo.videoId,
      af_web_dp: `https://loco.gg/stream/${pageInfo.videoId}`
    };
    urlType = UrlType.TYPE_VIDEO;
  }
  if (isCategoryPage && pageInfo.categoryId) {
    query = {
      ...query,
      type: 3,
      sub_type: 1,
      game_uid: pageInfo.categoryId
    };
  }
  if (isCategoriesPage) {
    query = {
      ...query,
      type: 1,
      sub_type: 1
    };
  }
  const result = await ShortUrlGetter({
    query,
    deepLinkRID,
    urlType,
    appsFlyerLink: donwloadLink
  });
  return result ? result : donwloadLink;
};
export default ShortUrlGetter;