import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { useDeviceInfo } from '@hooks/react-use';
import { useSidebarStore } from '@stores/sideBarStore';
import * as React from 'react';
export default function useSideBar() {
  const {
    width,
    screen_isDesktop
  } = useDeviceInfo();
  const isDesktopWidth = width && width >= 1024;
  React.useLayoutEffect(() => {
    const {
      setMobileView
    } = useControlDisplayStore.getState();
    setMobileView(!isDesktopWidth);
    if (screen_isDesktop && isDesktopWidth) {
      //  For first render screen_isDesktop is true even foe mobile because we update this value post-render
      useSidebarStore.getState().openSideBar();
    } else {
      useSidebarStore.getState().closeSideBar();
    }
  }, []);
  React.useEffect(() => {
    const {
      setMobileView
    } = useControlDisplayStore.getState();
    setMobileView(!isDesktopWidth);
    if (screen_isDesktop && isDesktopWidth) {
      //  For first render screen_isDesktop is true even foe mobile because we update this value post-render
      useSidebarStore.getState().openSideBar();
    } else {
      useSidebarStore.getState().closeSideBar();
    }
  }, [screen_isDesktop, isDesktopWidth]);
  return null;
}