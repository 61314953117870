function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useDeviceInfo } from '@hooks/react-use';
import Rodal from 'rodal';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
interface UnfollowConfirmationModalProps {
  isUnfolllowConfirmationModalOpen: boolean;
  onUnfolllowConfirmationModalClose: () => void;
  title: string;
  subTitle: string;
  handleUnfollow: () => void;
}
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1sbvlkw",
  styles: "display:flex;height:100%;flex-direction:column"
} : {
  name: "1nphrwl-UnfollowConfirmationModal",
  styles: "display:flex;height:100%;flex-direction:column;label:UnfollowConfirmationModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "11g4mt0",
  styles: "font-size:16px"
} : {
  name: "wtz8aj-UnfollowConfirmationModal",
  styles: "font-size:16px;label:UnfollowConfirmationModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "mmvz9h",
  styles: "font-weight:400"
} : {
  name: "pxku8i-UnfollowConfirmationModal",
  styles: "font-weight:400;label:UnfollowConfirmationModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "xk9brz",
  styles: "margin-top:0.5rem;font-size:12px;font-weight:500"
} : {
  name: "tvn7tf-UnfollowConfirmationModal",
  styles: "margin-top:0.5rem;font-size:12px;font-weight:500;label:UnfollowConfirmationModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1nlv2v5",
  styles: "display:flex;flex:1 1 0%;align-items:flex-end;justify-content:flex-end;font-size:16px;text-transform:uppercase;line-height:1rem"
} : {
  name: "1ydhgdc-UnfollowConfirmationModal",
  styles: "display:flex;flex:1 1 0%;align-items:flex-end;justify-content:flex-end;font-size:16px;text-transform:uppercase;line-height:1rem;label:UnfollowConfirmationModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "12w7m81",
  styles: "cursor:pointer;font-weight:600;:focus{outline:2px solid transparent;outline-offset:2px;}"
} : {
  name: "18hoiw4-UnfollowConfirmationModal",
  styles: "cursor:pointer;font-weight:600;:focus{outline:2px solid transparent;outline-offset:2px;};label:UnfollowConfirmationModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "x3dsk7",
  styles: "margin-left:1rem;cursor:pointer;font-weight:600;:focus{outline:2px solid transparent;outline-offset:2px;}"
} : {
  name: "vf40f-UnfollowConfirmationModal",
  styles: "margin-left:1rem;cursor:pointer;font-weight:600;:focus{outline:2px solid transparent;outline-offset:2px;};label:UnfollowConfirmationModal;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export const UnfollowConfirmationModal = ({
  onUnfolllowConfirmationModalClose,
  handleUnfollow,
  isUnfolllowConfirmationModalOpen,
  subTitle,
  title
}: UnfollowConfirmationModalProps): JSX.Element => {
  const {
    screen_isDesktop
  } = useDeviceInfo();
  return _jsx(Rodal, {
    visible: isUnfolllowConfirmationModalOpen,
    customStyles: {
      padding: '32px',
      width: !screen_isDesktop ? '100%' : '400px',
      background: '#262626',
      height: '150px',
      marginBottom: !screen_isDesktop ? '0px' : 'none',
      borderTopLeftRadius: !screen_isDesktop ? '20px' : 'none',
      borderTopRightRadius: !screen_isDesktop ? '20px' : 'none'
    },
    onClose: () => {
      onUnfolllowConfirmationModalClose();
    },
    showCloseButton: false,
    children: _jsxs("div", {
      css: _ref,
      children: [_jsxs("div", {
        css: _ref2,
        children: [_jsxs("div", {
          css: _ref3,
          children: ["Unfollow ", title]
        }), _jsxs("div", {
          css: _ref4,
          children: ["Are you sure you want to unfollow this ", subTitle, "?"]
        })]
      }), _jsxs("div", {
        css: _ref5,
        children: [_jsx("button", {
          css: _ref6,
          onClick: () => {
            onUnfolllowConfirmationModalClose();
          },
          children: "CANCEL"
        }), _jsx("button", {
          style: {
            color: '#FF6666'
          },
          css: _ref7,
          onClick: () => {
            handleUnfollow();
          },
          children: "UNFOLLOW"
        })]
      })]
    })
  });
};
export default UnfollowConfirmationModal;