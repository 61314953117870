import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useDeviceInfo } from '@hooks/react-use';
import { PAGES_ROUTE } from '@modules/analytics/types/constants';
import { useStreamStore } from '@stores/streamStore';
import { updateMetric } from '@stores/usePlayerEvents';
import { useRouter } from 'next/router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsPortalOpen
} = usePlayerStore.getState();
const {
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
const MiniPlayerToogle = ({
  disabled: isElementDisabled
}: {
  disabled?: boolean;
}) => {
  const {
    screen_isMobile
  } = useDeviceInfo();
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);
  const playerStreamId = usePlayerStore(state => state.playerProps.stream?.uid || null);
  const streamId = useStreamStore(state => state.streamID || null);
  const router = useRouter();
  const currentPath = router.pathname;
  const redirectToStream = () => {
    if (playerStreamId) {
      const videoPath = '/stream/' + playerStreamId;
      router.push(videoPath);
    }
  };
  const toogleMiniPlayer = () => {
    const isStreamPage = currentPath === PAGES_ROUTE.STREAM_PAGE;
    const isStreamerPage = currentPath === PAGES_ROUTE.STREAMER_PAGE;
    if (!isPortalOpen) {
      setIsPortalOpen(true);
      resetControlSectionVisiblity();
      return;
    }
    if (isStreamPage && isPortalOpen) {
      const streamerPageScroll = (document.getElementById('streamer_page_wrapper') as HTMLDivElement | undefined);
      streamerPageScroll?.scrollTo(0, 0);
      setIsPortalOpen(false);
      return;
    }
    if (isStreamerPage && isPortalOpen) {
      if (streamId) {
        const streamerPageScroll = (document.getElementById('streamer_page_wrapper') as HTMLDivElement | undefined);
        streamerPageScroll?.scrollTo(0, 0);
        setIsPortalOpen(false);
      } else {
        updateMetric(playerStreamId || '', 'player_pip_expand', true);
        redirectToStream();
      }
      return;
    }
    // Here will redirect user to stream page
    updateMetric(playerStreamId || '', 'player_pip_expand', true);
    redirectToStream();
  };
  if (screen_isMobile) {
    return null;
  }
  return _jsx("button", {
    disabled: isElementDisabled,
    "aria-disabled": isElementDisabled,
    className: `control-tooltip-parent miniplayer-toggle ` + (isPortalOpen ? 'miniplayer-toggle-on' : 'miniplayer-toggle-off'),
    onPointerDown: toogleMiniPlayer,
    children: isPortalOpen ? _jsx("svg", {
      width: "16",
      height: "16",
      viewBox: "0 0 18 18",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg",
      children: _jsx("path", {
        d: "M7.845 0.75C7.845 0.551088 7.76598 0.360322 7.62533 0.21967C7.48468 0.0790178 7.29391 0 7.095 0H0.75C0.551088 0 0.360322 0.0790178 0.21967 0.21967C0.0790178 0.360322 0 0.551088 0 0.75V7.095C0 7.29391 0.0790178 7.48468 0.21967 7.62533C0.360322 7.76598 0.551088 7.845 0.75 7.845C0.948912 7.845 1.13968 7.76598 1.28033 7.62533C1.42098 7.48468 1.5 7.29391 1.5 7.095V2.562L6.5655 7.6275C6.70695 7.76412 6.8964 7.83971 7.09305 7.83801C7.2897 7.8363 7.47781 7.75742 7.61686 7.61836C7.75592 7.47931 7.8348 7.2912 7.83651 7.09455C7.83821 6.8979 7.76262 6.70845 7.626 6.567L2.5605 1.5H7.0965C7.29541 1.5 7.48618 1.42098 7.62683 1.28033C7.76748 1.13968 7.8465 0.948912 7.8465 0.75H7.845ZM14.25 1.5C14.8467 1.5 15.419 1.73705 15.841 2.15901C16.2629 2.58097 16.5 3.15326 16.5 3.75V9H11.655C10.9509 9 10.2755 9.27972 9.77763 9.77763C9.27972 10.2755 9 10.9509 9 11.655V16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V10.905C1.5 10.7061 1.42098 10.5153 1.28033 10.3747C1.13968 10.234 0.948912 10.155 0.75 10.155C0.551088 10.155 0.360322 10.234 0.21967 10.3747C0.0790178 10.5153 0 10.7061 0 10.905V14.25C0 15.2446 0.395088 16.1984 1.09835 16.9016C1.80161 17.6049 2.75544 18 3.75 18H14.25C15.2446 18 16.1984 17.6049 16.9016 16.9016C17.6049 16.1984 18 15.2446 18 14.25V3.75C18 2.75544 17.6049 1.80161 16.9016 1.09835C16.1984 0.395088 15.2446 0 14.25 0H10.905C10.7061 0 10.5153 0.0790178 10.3747 0.21967C10.234 0.360322 10.155 0.551088 10.155 0.75C10.155 0.948912 10.234 1.13968 10.3747 1.28033C10.5153 1.42098 10.7061 1.5 10.905 1.5H14.25Z",
        fill: "white"
      })
    }) : _jsxs(_Fragment, {
      children: [_jsx("span", {
        className: "control-tooltip",
        children: "Miniplayer"
      }), _jsx("svg", {
        style: {
          opacity: isElementDisabled ? 0.5 : 1
        },
        width: "20",
        height: "16",
        viewBox: "0 0 22 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
        children: _jsx("path", {
          fillRule: "evenodd",
          clipRule: "evenodd",
          d: "M21.9999 16V1.98C21.9999 0.88 21.0999 0 19.9999 0H1.99988C0.899878 0 -0.00012207 0.88 -0.00012207 1.98V16C-0.00012207 17.1 0.899878 18 1.99988 18H19.9999C21.0999 18 21.9999 17.1 21.9999 16ZM17.0001 8.00024H11.0001C10.4501 8.00024 10.0001 8.45024 10.0001 9.00024V13.0002C10.0001 13.5502 10.4501 14.0002 11.0001 14.0002H17.0001C17.5501 14.0002 18.0001 13.5502 18.0001 13.0002V9.00024C18.0001 8.45024 17.5501 8.00024 17.0001 8.00024ZM3 16.0198H19C19.55 16.0198 20 15.5698 20 15.0198V2.96985C20 2.41985 19.55 1.96985 19 1.96985H3C2.45 1.96985 2 2.41985 2 2.96985V15.0198C2 15.5698 2.45 16.0198 3 16.0198Z",
          fill: "white"
        })
      })]
    })
  });
};
export default MiniPlayerToogle;