import { useAuthStore } from '@stores/authStore';
import { isWebProd } from '@utils/platform';
import dayjs from 'dayjs';
export const setupGlobalSetterOnAds = (isLoggedIn: boolean) => {
  if (!window.VMAXSDK) return;
  if (isWebProd()) {
    window.VMAXSDK.App.setSource('loco.gg');
  } else {
    window.VMAXSDK.App.setSource('stage2.loco.gg');
  }
  setVMAXDeviceType();
  if (isLoggedIn) {
    const birthdate = dayjs(useAuthStore.getState().me?.dob, 'DD/MM/YYYY');
    const ageInYears = dayjs().diff(birthdate, 'year');
    const email = useAuthStore.getState().me?.email;
    const gender = useAuthStore.getState().me?.gender;
    const genderInString = gender === 1 ? window.VMAXSDK.Enums.Gender.MALE : gender === 2 ? window.VMAXSDK.Enums.Gender.FEMALE : gender === 3 ? '' : '';
    window.VMAXSDK.User.setAge(ageInYears);
    window.VMAXSDK.User.setEmail(email);
    window.VMAXSDK.User.setGender(genderInString);
  } else {
    window.VMAXSDK.User.setAge('');
    window.VMAXSDK.User.setEmail('');
    window.VMAXSDK.User.setGender('');
  }
};

// Check device type based on various properties and methods
export function setVMAXDeviceType() {
  if (!window.VMAXSDK) return;
  const isMobile = /Mobi/.test(navigator.userAgent);
  const isTablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(navigator.userAgent);
  const isTV = /TV/.test(navigator.userAgent);
  const isSTB = /STB/.test(navigator.userAgent);
  const isDesktop = !isMobile && !isTablet && !isTV && !isSTB;
  const isiPhone = /iPhone/.test(navigator.userAgent);
  const isAndroid = /Android/.test(navigator.userAgent);
  if (isMobile || isiPhone || isAndroid) {
    window.VMAXSDK.Device.setDeviceType(window.VMAXSDK.Enums.DeviceType.Phone);
  } else if (isTablet) {
    window.VMAXSDK.Device.setDeviceType(window.VMAXSDK.Enums.DeviceType.Tablet);
  } else if (isTV) {
    window.VMAXSDK.Device.setDeviceType(window.VMAXSDK.Enums.DeviceType.TV);
  } else if (isSTB) {
    window.VMAXSDK.Device.setDeviceType(window.VMAXSDK.Enums.DeviceType.STB);
  } else if (isDesktop) {
    window.VMAXSDK.Device.setDeviceType(window.VMAXSDK.Enums.DeviceType.PC);
  } else {
    window.VMAXSDK.Device.setDeviceType(window.VMAXSDK.Enums.DeviceType.ConnectedDevice);
  }
}