function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { PLAYER_KEY_MAPPING } from '@components/VideoPlayer/constants';
import { useDeviceInfo } from '@hooks/react-use';
import { UpwardArrow } from './HelperComponents';
import MenuItem from './MenuItem';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1wnowod",
  styles: "display:flex;align-items:center;justify-content:center"
} : {
  name: "2tk4sp-KeyboardSetting",
  styles: "display:flex;align-items:center;justify-content:center;label:KeyboardSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "cv84fr",
  styles: "margin-left:0.25rem;margin-right:0.25rem"
} : {
  name: "11fpi3r-KeyboardSetting",
  styles: "margin-left:0.25rem;margin-right:0.25rem;label:KeyboardSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "ecw5d0",
  styles: "--tw-rotate:180deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
} : {
  name: "7edh6t-KeyboardSetting",
  styles: "--tw-rotate:180deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));label:KeyboardSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1wnowod",
  styles: "display:flex;align-items:center;justify-content:center"
} : {
  name: "2tk4sp-KeyboardSetting",
  styles: "display:flex;align-items:center;justify-content:center;label:KeyboardSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1ihf926",
  styles: "--tw-rotate:-90deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
} : {
  name: "1vjg57f-KeyboardSetting",
  styles: "--tw-rotate:-90deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));label:KeyboardSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "cv84fr",
  styles: "margin-left:0.25rem;margin-right:0.25rem"
} : {
  name: "11fpi3r-KeyboardSetting",
  styles: "margin-left:0.25rem;margin-right:0.25rem;label:KeyboardSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "blres4",
  styles: "--tw-rotate:90deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
} : {
  name: "1nx69i3-KeyboardSetting",
  styles: "--tw-rotate:90deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));label:KeyboardSetting;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const KeyboardSetting = () => {
  const {
    screen_isMobile,
    device_isMobile
  } = useDeviceInfo();
  return _jsxs(_Fragment, {
    children: [_jsx(MenuItem, {
      className: "keyboard-shortcuts-list",
      title: "Play/Pause",
      value: 'K / Space',
      hideArrow: true,
      isSubMenu: true
    }), _jsx(MenuItem, {
      className: "keyboard-shortcuts-list",
      title: "Mute/Unmute",
      value: PLAYER_KEY_MAPPING.mute_unmute,
      hideArrow: true,
      isSubMenu: true
    }), _jsx(MenuItem, {
      className: "keyboard-shortcuts-list",
      title: "Volume Up/Down",
      value: _jsxs("div", {
        css: _ref,
        children: [_jsx("span", {
          children: UpwardArrow
        }), _jsx("span", {
          css: _ref2,
          children: "/"
        }), _jsx("span", {
          css: _ref3,
          children: UpwardArrow
        })]
      }),
      hideArrow: true,
      isSubMenu: true
    }), _jsx(MenuItem, {
      className: "keyboard-shortcuts-list",
      title: "Forward/Backward (+/- 10s)",
      value: _jsxs("div", {
        css: _ref4,
        children: [_jsx("span", {
          css: _ref5,
          children: UpwardArrow
        }), _jsx("span", {
          css: _ref6,
          children: "/"
        }), _jsx("span", {
          css: _ref7,
          children: UpwardArrow
        })]
      }),
      hideArrow: true,
      isSubMenu: true
    }), _jsx(MenuItem, {
      className: "keyboard-shortcuts-list",
      title: "Full Screen",
      value: PLAYER_KEY_MAPPING.full_screen,
      hideArrow: true,
      isSubMenu: true
    }), _jsx(MenuItem, {
      className: "keyboard-shortcuts-list",
      title: "Theatre Mode",
      value: PLAYER_KEY_MAPPING.theatre_mode,
      hideArrow: true,
      isSubMenu: true
    }), _jsx(MenuItem, {
      className: "keyboard-shortcuts-list",
      title: 'Collapse Chat / Recommendations',
      value: PLAYER_KEY_MAPPING.chat_view,
      hideArrow: true,
      isSubMenu: true
    }), !(screen_isMobile || device_isMobile) && _jsx(MenuItem, {
      className: "keyboard-shortcuts-list",
      title: "Mini Player",
      value: PLAYER_KEY_MAPPING.mini_player,
      hideArrow: true,
      isSubMenu: true
    })]
  });
};
export default KeyboardSetting;