/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { STREAMER_TYPE, StreamInterface } from '@customTypes/index';
import { unicornEvents } from '@functions/stream';
import { isWebview } from '@layouts/core/Header/headerRightLogo';
import { Amplitude } from '@modules/analytics';
import { useAdsStore } from '@stores/adsStore';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { useProfileStore } from '@stores/profileStore';
import { useStreamStore } from '@stores/streamStore';
import { setupGlobalSetterOnAds } from '@utils/ads';
import { isProduction, isWebProd } from '@utils/platform';
import dayjs from 'dayjs';
import isMobile from 'is-mobile';
import { AdInfo, GoogleIMAAdInfo, InHouseAdInfo, onErrorAdResponse, VMaxSdk_TS } from './VMAXSDK_TYPES';
declare global {
  interface Window {
    VMAXSDK: VMaxSdk_TS | undefined;
  }
}
function isGoogleIMAAdInfo(adInfo: AdInfo): adInfo is GoogleIMAAdInfo {
  return 'adId' in adInfo; // or another unique property of GoogleIMAAdInfo
}

function isInHouseAdInfo(adInfo: AdInfo): adInfo is InHouseAdInfo {
  return 'ad' in adInfo; // or another unique property of InHouseAdInfo
}

const IS_STAGING = process.env.IS_STAGING ? JSON.parse(process.env.IS_STAGING) : false;
const {
  setIsMuted
} = usePlayerStore.getState();
const {
  setIsPlaying
} = useStreamStore.getState();
export default function setUpAds() {
  if (!window.VMAXSDK) return;
  const isLoggedIn = !!useAuthStore.getState().me?.user_uid;
  const setAdPlaying = useAdsStore.getState().setAdPlaying;
  const setAdMuted = useAdsStore.getState().setAdMuted;
  const updateDuration = useAdsStore.getState().updateDuration;
  const updateVolume = useAdsStore.getState().updateVolume;
  setupGlobalSetterOnAds(isLoggedIn);
  const EventsType = window.VMAXSDK.Event.Type;
  const allEvents = Object.values(window.VMAXSDK.Event.Type);
  for (const item of allEvents) {
    window.VMAXSDK.Events.remove(item, 'a1');
  }
  window.VMAXSDK.Events.listen(EventsType.AD_LOADED, 'a1', function (data) {
    const adType = useAdsStore.getState().adType;
    updateDuration({
      currentTime: data.adInfo?.currentTime || 0,
      totalTime: data.adInfo?.duration || 0
    });
    if (isGoogleIMAAdInfo(data.adInfo) && adType) {
      loadAd(adType, data?.adInfo.adId);
    } else if (isInHouseAdInfo(data.adInfo) && adType) {
      loadAd(adType, data?.adInfo.ad.id);
    } else {
      console.error('Unknown ad type');
    }
  });
  window.VMAXSDK.Events.listen(EventsType.AD_READY, 'a1', function (data) {
    let ad = {};
    if (isGoogleIMAAdInfo(data.adInfo)) {
      ad = {
        id: data.adInfo.adId,
        name: data.adInfo.advertiserName,
        delay: data.adInfo.skipTimeOffset,
        'total-duration': data.adInfo.duration,
        'playback-duration': data.adInfo.duration
      };
    }
    if (isInHouseAdInfo(data.adInfo)) {
      ad = {
        id: data.adInfo.ad.id,
        name: data.adInfo.ad.name,
        delay: data.adInfo.ad.delay,
        'total-duration': data.adInfo.ad['total-duration'],
        'playback-duration': data.adInfo.ad['playback-duration']
      };
    }
    if (ad) {
      const isMuted = usePlayerStore.getState().isMuted;
      useAdsStore.getState().setLastMuteState(isMuted);
      window.VMAXSDK?.setVolume({
        placementId: 'a1',
        volume: 0
      });
      window.VMAXSDK?.muteAd('a1');

      //@ts-ignore
      useAdsStore.getState().setAd(ad);
      // Encounter issue that this container is not present in HTML, due to api call before showing Video player
      // Moved this to IVSPLAYER/index.tsx file
      // Here we was showing ADS, window.VMAXSDK.showAd({...
    } else {
      console.warn('vMAX-> not able to load ad');
    }
  });
  window.VMAXSDK.Events.listen(EventsType.AD_CLICK, 'a1', function () {
    clickOnAdFunc();
  });
  window.VMAXSDK.Events.listen(EventsType.AD_RENDER, 'a1', function () {});
  window.VMAXSDK.Events.listen(EventsType.AD_CLOSE, 'a1', function () {
    removeInsAndVideoContainer();
  });
  window.VMAXSDK.Events.listen(EventsType.AD_ERROR, 'a1', function (data) {
    errorAdFunc(data);
  });
  window.VMAXSDK.Events.listen(EventsType.AD_MEDIA_START, 'a1', function (data) {
    let isAdPresent = true; // because this function called
    try {
      isAdPresent = !!window.VMAXSDK?.getVmaxAd('a1');
    } catch (e) {
      isAdPresent = false; // somehow not present
    }

    if (isAdPresent) {
      setAdMuted(data.adInfo?.muted);
      updateVolume(data.adInfo?.volume);
      updateDuration({
        currentTime: data.adInfo?.currentTime,
        totalTime: data.adInfo?.duration
      });
      handlePlayerStateForAd(true, true);
      setAdPlaying(true);
    }
  });
  window.VMAXSDK.Events.listen(EventsType.AD_TIME_UPDATE, 'a1', function (data) {
    setAdMuted(data.adInfo?.muted);
    updateVolume(data.adInfo?.volume);
    updateDuration({
      currentTime: data.adInfo?.currentTime,
      totalTime: data.adInfo?.duration
    });
  });
  window.VMAXSDK.Events.listen(EventsType.AD_MEDIA_PAUSE, 'a1', function () {
    setAdPlaying(false);
  });
  window.VMAXSDK.Events.listen(EventsType.AD_MEDIA_RESUME, 'a1', function () {
    setAdPlaying(true);
  });
  window.VMAXSDK.Events.listen(EventsType.AD_MEDIA_COMPLETE, 'a1', function () {
    completeWatchAdFun(false);
  });
  window.VMAXSDK.Events.listen(EventsType.AD_SKIPPED, 'a1', function () {
    completeWatchAdFun(true);
  });
  window.VMAXSDK.Events.listen(EventsType.AD_MEDIA_UNMUTE, 'a1', function (data) {
    const initialVolume = usePlayerStore.getState().volume;
    // Since it is unmuting, therefore udpate the value to 1, incase we have 0
    window.VMAXSDK?.setVolume({
      placementId: 'a1',
      volume: data.adInfo?.volume || initialVolume || 1
    });
    updateVolume(initialVolume);
    setAdMuted(data.adInfo?.muted);
    useAdsStore.getState().setLastMuteState(!!data.adInfo?.muted);
  });
  window.VMAXSDK.Events.listen(EventsType.AD_MEDIA_MUTE, 'a1', function (data) {
    updateVolume(data.adInfo?.volume);
    setAdMuted(data.adInfo?.muted);
    useAdsStore.getState().setLastMuteState(!!data.adInfo?.muted);
  });
}
const clickOnAdFunc = () => {
  const adType = useAdsStore.getState().adType;
  const ad = useAdsStore.getState().ad;
  const stream = useStreamStore.getState().stream;
  if (!ad || !adType || !stream?.uid) {
    return;
  }
  const fingerprint = localStorage.getItem('fingerprint');
  const me = useAuthStore.getState().me;
  const wallet = useProfileStore.getState().wallet;
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  const botInfo = useAppStore.getState().botInfo;
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: 'click_ad',
      props: {
        ad_id: ad.id,
        ad_type: adType,
        streamer_name: stream.streamer.username,
        video_id: stream.uid,
        streamer_id: stream.streamer.user_uid,
        video_type: stream.status,
        skip_offset: ad.delay,
        bot_probable: botInfo?.bot,
        bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
        bk_device_id: fingerprint ? fingerprint : undefined,
        bk_user_id: me?.user_uid ? me.user_uid : undefined,
        web_platform: 'loco.gg',
        web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
      }
    }]
  };
  if (isProduction()) {
    unicornEvents(value);
  }
  Amplitude.logEvent('click_ad', {
    ad_id: ad.id,
    ad_type: adType,
    streamer_name: stream.streamer.username,
    video_id: stream.uid,
    streamer_id: stream.streamer.user_uid,
    video_type: stream.status,
    skip_offset: ad.delay
  });
};
const errorAdFunc = (data: onErrorAdResponse) => {
  const adType = useAdsStore.getState().adType;
  const stream = useStreamStore.getState().stream;
  if (!stream?.uid) {
    return;
  }
  // just making sure that we are ignoring error and doesn't not inforce any condition if error is coming in adInfo obj
  if (data?.error?.errorCode) {
    if (adType === 'pre_roll') {
      useAdsStore.getState().updateStreamWatchDuration(0);
      useAdsStore.getState().updateLastInstreamAdTime();
    }
    if (adType === 'mid_roll') {
      useAdsStore.getState().updateStreamWatchDuration(0);
    }
  }
  const fingerprint = localStorage.getItem('fingerprint');
  const me = useAuthStore.getState().me;
  const wallet = useProfileStore.getState().wallet;
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  const botInfo = useAppStore.getState().botInfo;
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: 'error_ad',
      props: {
        video_id: stream?.uid,
        streamer_id: stream?.streamer.user_uid,
        video_type: stream?.status,
        streamer_name: stream?.streamer.username,
        ad_type: adType,
        error_code: data?.error?.errorCode ? data.error.errorCode : data.adInfo?.errorCode ? data.adInfo?.errorCode : 1000,
        error_description: data?.error?.errorDescription ? data.error.errorDescription : data.adInfo?.errorDescription ? data.adInfo?.errorDescription : 'something went wrong',
        bot_probable: botInfo?.bot,
        bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
        bk_device_id: fingerprint ? fingerprint : undefined,
        bk_user_id: me?.user_uid ? me.user_uid : undefined,
        web_platform: 'loco.gg',
        web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
      }
    }]
  };
  if (isProduction()) {
    unicornEvents(value);
  }
  Amplitude.logEvent('error_ad', {
    video_id: stream?.uid,
    streamer_id: stream?.streamer.user_uid,
    video_type: stream?.status,
    streamer_name: stream?.streamer.username,
    ad_type: adType,
    error_code: data?.error?.errorCode ? data.error.errorCode : data.adInfo?.errorCode ? data.adInfo?.errorCode : 1000,
    error_description: data?.error?.errorDescription ? data.error.errorDescription : data.adInfo?.errorDescription ? data.adInfo?.errorDescription : 'something went wrong'
  });
  removeInsAndVideoContainer();
};
export function removeInsAndVideoContainer() {
  const myNode = document.getElementById('ins_container');
  if (myNode) myNode.innerHTML = '';
  handlePlayerStateForAd(false);
  useAdsStore.getState().setAdPlaying(false);
  useAdsStore.getState().setAd(null);
}

// now let's say se we get data-adsport id then we need to all data properties so we can get custom ads

export function insertINSTag(adsSpotKey = '3e0b43a7', streamer_id?: string, game_id?: string) {
  if (!window.VMAXSDK || useAdsStore.getState().isNotShowAds) return;
  const insContainer = document.getElementById('ins_container');
  const isLoggedIn = !!useAuthStore.getState().me?.user_uid;
  if (insContainer) {
    if (insContainer.hasChildNodes()) {
      console.log('continue to play last ads');
    } else {
      const insElement = document.createElement('ins');
      insElement.id = 'a1';
      insElement.setAttribute('data-adspot-key', adsSpotKey);
      insElement.setAttribute('data-ad-container', 'video_container_locogg_ads');
      insElement.setAttribute('data-adtest', 'on');

      // insElement.setAttribute('data-adtest', 'on')
      const userTypeNumber = useAuthStore.getState().me?.user_type;
      const pageInfo = useAppStore.getState().pageInfo;
      const userType = pageInfo?.streamerCategory ?? userTypeNumber === STREAMER_TYPE.viewer ? 'viewer' : userTypeNumber === STREAMER_TYPE.organic || userTypeNumber === STREAMER_TYPE.partner ? 'streamer' : 'guest';
      const {
        os_name
      } = useAppStore.getState().deviceInfo;
      if (streamer_id && game_id) {
        insElement.dataset.setcustomdata = JSON.stringify({
          streamer_id: streamer_id,
          category_id: game_id,
          user_type: userType,
          screen: 'stream',
          deviceos: os_name
        });
      }
      if (isLoggedIn) {
        const birthdate = dayjs(useAuthStore.getState().me?.dob, 'DD/MM/YYYY');
        const ageInYears = dayjs().diff(birthdate, 'year');
        const email = useAuthStore.getState().me?.email;
        const gender = useAuthStore.getState().me?.gender;
        const genderInString = gender === 1 ? window.VMAXSDK.Enums.Gender.MALE : gender === 2 ? window.VMAXSDK.Enums.Gender.FEMALE : gender === 3 ? '' : '';
        if (ageInYears) {
          insElement.dataset.age = `${ageInYears}`;
        }
        if (email) {
          insElement.dataset.email = email;
        }
        if (genderInString) {
          insElement.dataset.gender = genderInString;
        }
      }
      insContainer.appendChild(insElement);
    }
  }
}
const completeWatchAdFun = (is_skipped: boolean) => {
  const stream = useStreamStore.getState().stream;
  if (!stream?.uid) {
    return;
  }
  useAdsStore.getState().updateLastInstreamAdTime();
  useAdsStore.getState().updateStreamWatchDuration(0);
  useAdsStore.getState().setAdPlaying(false);
  const adType = useAdsStore.getState().adType;
  const preRollAdsWatchedByUser = useAdsStore.getState().preRollAdsWatchedByUser;
  const midRollAdsWatchedByUser = useAdsStore.getState().midRollAdsWatchedByUser;
  useAdsStore.getState().incrementAdCount('totalAdsWatchedOnStream');
  if (adType === 'pre_roll') {
    Amplitude.setAmplitudeIdentity({
      pre_roll_ads_watched: preRollAdsWatchedByUser + 1
    });
    useAdsStore.getState().incrementAdCount('preRollAdsWatchedByUser');
  }
  if (adType === 'mid_roll') {
    Amplitude.setAmplitudeIdentity({
      mid_roll_ads_watched: midRollAdsWatchedByUser + 1
    });
    useAdsStore.getState().incrementAdCount('midRollAdsWatchedByUser');
  }
  const ad = useAdsStore.getState().ad;
  const streamerName = stream?.streamer.username;
  const streamID = stream?.uid;
  const streamerID = stream?.streamer.user_uid;
  const videoType = stream?.status;
  const userVipType = useProfileStore.getState().vipType;
  if (ad && adType && streamerName && streamID && videoType && streamerID) {
    const fingerprint = localStorage.getItem('fingerprint');
    const me = useAuthStore.getState().me;
    const wallet = useProfileStore.getState().wallet;
    const {
      is_charging,
      manufacturer,
      network_speed_kbps,
      network_type,
      os_name,
      os_version,
      screen,
      browser,
      browserVersion,
      browserMajorVersion,
      cookies
    } = useAppStore.getState().deviceInfo;
    const botInfo = useAppStore.getState().botInfo;
    const value = {
      context: {
        device_id: fingerprint,
        env: IS_STAGING ? 'staging' : 'prod',
        gold_count: me?.username ? wallet.gold : undefined,
        is_charging: is_charging,
        logged_in: me?.username ? true : false,
        manufacturer: manufacturer,
        network_speed_kbps: network_speed_kbps,
        network_type: network_type,
        origin: 'client',
        os_name: os_name,
        os_version: os_version,
        browser_screen: screen,
        browser_name: browser,
        browser_version: browserVersion,
        browser_major_version: browserMajorVersion,
        browser_cookies_enable: cookies,
        platform: 7,
        streamer: me?.can_stream ? true : false,
        user_uid: me?.user_uid ? me.user_uid : undefined,
        username: me?.username ? me.username : undefined
      },
      events: [{
        event_name: 'watch_ad',
        props: {
          ad_id: ad.id,
          ad_type: adType,
          streamer_name: streamerName,
          video_id: streamID,
          streamer_id: streamerID,
          video_type: videoType,
          skip_offset: ad.delay,
          is_skipped: is_skipped,
          bot_probable: botInfo?.bot,
          is_vip: userVipType || 'non-vip',
          bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
          bk_device_id: fingerprint ? fingerprint : undefined,
          bk_user_id: me?.user_uid ? me.user_uid : undefined,
          web_platform: 'loco.gg',
          web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
        }
      }]
    };
    if (isProduction()) {
      unicornEvents(value);
    }
    Amplitude.logEvent('watch_ad', {
      ad_id: ad.id,
      ad_type: adType,
      streamer_name: streamerName,
      video_id: streamID,
      streamer_id: streamerID,
      video_type: videoType,
      skip_offset: ad.delay,
      is_skipped: is_skipped,
      is_vip: userVipType || 'non-vip'
    });
  }
};
export const closeAdFunc = (stream: StreamInterface) => {
  const adType = useAdsStore.getState().adType;
  const ad = useAdsStore.getState().ad;
  const streamerName = stream.streamer.username;
  const streamID = stream?.uid;
  const streamerID = stream?.streamer.user_uid;
  const videoType = stream?.status;
  useAdsStore.getState().setShowInStreamAdControl(false);
  if (ad && adType && streamerName && streamID && videoType && streamerID) {
    useAdsStore.getState().incrementAdCount('totalAdsCloseOnStream');
    const fingerprint = localStorage.getItem('fingerprint');
    const me = useAuthStore.getState().me;
    const wallet = useProfileStore.getState().wallet;
    const {
      is_charging,
      manufacturer,
      network_speed_kbps,
      network_type,
      os_name,
      os_version,
      screen,
      browser,
      browserVersion,
      browserMajorVersion,
      cookies
    } = useAppStore.getState().deviceInfo;
    const botInfo = useAppStore.getState().botInfo;
    const value = {
      context: {
        device_id: fingerprint,
        env: IS_STAGING ? 'staging' : 'prod',
        gold_count: me?.username ? wallet.gold : undefined,
        is_charging: is_charging,
        logged_in: me?.username ? true : false,
        manufacturer: manufacturer,
        network_speed_kbps: network_speed_kbps,
        network_type: network_type,
        origin: 'client',
        os_name: os_name,
        os_version: os_version,
        browser_screen: screen,
        browser_name: browser,
        browser_version: browserVersion,
        browser_major_version: browserMajorVersion,
        browser_cookies_enable: cookies,
        platform: 7,
        streamer: me?.can_stream ? true : false,
        user_uid: me?.user_uid ? me.user_uid : undefined,
        username: me?.username ? me.username : undefined
      },
      events: [{
        event_name: 'close_ad',
        props: {
          ad_id: ad.id,
          ad_type: adType,
          streamer_name: streamerName,
          video_id: streamID,
          streamer_id: streamerID,
          video_type: videoType,
          skip_offset: ad.delay,
          bot_probable: botInfo?.bot,
          bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
          bk_device_id: fingerprint ? fingerprint : undefined,
          bk_user_id: me?.user_uid ? me.user_uid : undefined,
          web_platform: 'loco.gg',
          web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
        }
      }]
    };
    if (isProduction()) {
      unicornEvents(value);
    }
    Amplitude.logEvent('close_ad', {
      ad_id: ad.id,
      ad_type: adType,
      streamer_name: streamerName,
      video_id: streamID,
      streamer_id: streamerID,
      video_type: videoType,
      skip_offset: ad.delay
    });
    if (document.getElementById('a1') && window.VMAXSDK) {
      window.VMAXSDK.closeAd('a1');
    }
    if (adType === 'pre_roll') {
      Amplitude.addAmplitudeIdentity({
        pre_roll_ads_closed: 1
      });
    }
    if (adType === 'mid_roll') {
      Amplitude.addAmplitudeIdentity({
        mid_roll_ads_closed: 1
      });
    }
    removeInsAndVideoContainer();
  }
};
const loadAd = (ad_type: string, ad_id: string) => {
  if (ad_id && ad_type) {
    handlePlayerStateForAd(true); // Ad is loaded, thus AD black UI is been shown by VMAX
    Amplitude.logEvent('load_ad', {
      ad_type,
      ad_id
    });
    const fingerprint = localStorage.getItem('fingerprint');
    const me = useAuthStore.getState().me;
    const wallet = useProfileStore.getState().wallet;
    const {
      is_charging,
      manufacturer,
      network_speed_kbps,
      network_type,
      os_name,
      os_version,
      screen,
      browser,
      browserVersion,
      browserMajorVersion,
      cookies
    } = useAppStore.getState().deviceInfo;
    const botInfo = useAppStore.getState().botInfo;
    const value = {
      context: {
        device_id: fingerprint,
        env: IS_STAGING ? 'staging' : 'prod',
        gold_count: me?.username ? wallet.gold : undefined,
        is_charging: is_charging,
        logged_in: me?.username ? true : false,
        manufacturer: manufacturer,
        network_speed_kbps: network_speed_kbps,
        network_type: network_type,
        origin: 'client',
        os_name: os_name,
        os_version: os_version,
        browser_screen: screen,
        browser_name: browser,
        browser_version: browserVersion,
        browser_major_version: browserMajorVersion,
        browser_cookies_enable: cookies,
        platform: 7,
        streamer: me?.can_stream ? true : false,
        user_uid: me?.user_uid ? me.user_uid : undefined,
        username: me?.username ? me.username : undefined
      },
      events: [{
        event_name: 'load_ad',
        props: {
          ad_type: ad_type,
          ad_id: ad_id,
          bot_probable: botInfo?.bot,
          bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
          bk_device_id: fingerprint ? fingerprint : undefined,
          bk_user_id: me?.user_uid ? me.user_uid : undefined,
          web_platform: 'loco.gg',
          web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
        }
      }]
    };
    if (isProduction()) {
      unicornEvents(value);
    }
  }
};
const isAdReallyChangePlayerState = {
  current: false
};
const handlePlayerStateForAd = (addingAd: boolean, isMedia_start?: boolean) => {
  if (!window.VMAXSDK) return;
  {
    isMedia_start;
  }
  if (addingAd) {
    isAdReallyChangePlayerState.current = true;
    useAdsStore.getState().setShowInStreamAdControl(true);
    const initialVolume = usePlayerStore.getState().volume;
    // Since we set lastMuteStateOfPlayer to null in this else condition,
    // thus is we have boolean, that means that boolean in last state of player....
    const isInitiallyMuted = typeof useAdsStore.getState().lastMuteStateOfPlayer === 'boolean' ? useAdsStore.getState().lastMuteStateOfPlayer : usePlayerStore.getState().isMuted;
    if (isInitiallyMuted || !isMedia_start) {
      // Mute Ad when Ad is loaded
      window.VMAXSDK.setVolume({
        placementId: 'a1',
        volume: 0
      });
      window.VMAXSDK.muteAd('a1');
    } else {
      window.VMAXSDK.setVolume({
        placementId: 'a1',
        volume: initialVolume
      });
      window.VMAXSDK.unmuteAd('a1');
    }
    if (useStreamStore.getState().isLive) {
      useAdsStore.getState().setLastMuteState(isInitiallyMuted);
      setIsMuted(true);
    } else {
      setIsPlaying(false);
    }
  } else if (isAdReallyChangePlayerState.current) {
    isAdReallyChangePlayerState.current = false;
    useAdsStore.getState().setShowInStreamAdControl(false);
    const lastMuteStateOfPlayer = !!useAdsStore.getState().lastMuteStateOfPlayer;
    // Set to null, So that We will fetch the value in above if condition
    useAdsStore.getState().setLastMuteState(null);
    let isAdPresent = true; // because this function called
    try {
      isAdPresent = !!window.VMAXSDK?.getVmaxAd('a1');
    } catch (e) {
      isAdPresent = false; // somehow not present
    }

    if (isAdPresent) {
      window.VMAXSDK.setVolume({
        placementId: 'a1',
        volume: 0
      });
      window.VMAXSDK.muteAd('a1');
    }
    if (useStreamStore.getState().streamID) {
      if (useStreamStore.getState().isLive) {
        setIsMuted(lastMuteStateOfPlayer);
      } else {
        // if ads in playing on vod then we can play content once ads is played or skip or whatever
        if (!useStreamStore.getState().isPlaying) {
          setIsPlaying(true);
        }
      }
    }
  }
};
export const getStreamAdspotKey = () => {
  if (isWebProd()) {
    return {
      // For loco.gg
      PRE_ROLL: 'edeb2a72',
      PRE_ROLL_MOBILE: '74e27bc8',
      MID_ROLL: 'f39ddf2a',
      MID_ROLL_MOBILE: '1d93be06'
    };
  }
  return {
    // For stage2.loco.gg
    PRE_ROLL: '9aec1ae4',
    PRE_ROLL_MOBILE: 'ea86ee6b',
    MID_ROLL: '849aefbc',
    MID_ROLL_MOBILE: '6a948e90'
  };
};