/**
 * PLATFORM UTILS
 *
 * Utils to detect user devices and next.js runtimes
 */

/**
 * Checks whether the current runtime is a browser
 * @return {boolean}
 */
export const isBrowser = (): boolean => typeof window !== 'undefined';
export const isDevelopment = (): boolean => {
  return process.env.NODE_ENV === 'development';
};
export const isProduction = () => {
  return process.env.NODE_ENV === 'production';
};
export const isTest = (): boolean => {
  return process.env.NODE_ENV === 'test';
};
export const isServer = !(typeof window !== 'undefined' && window.document && window.document.createElement);
export const isWebProd = () => typeof window !== 'undefined' && window.location.origin === 'https://loco.gg';