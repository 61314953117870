function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { isFullScreenApiAvailable } from '@components/VideoPlayer/Utils/utils';
import { useStreamStore } from '@stores/streamStore';
import React, { useEffect, useState } from 'react';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsFullScreen
} = useStreamStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "bhrf83",
  styles: "height:1.425rem;min-height:1.425rem;width:1.425rem;min-width:1.425rem;border-width:0px;background-image:none"
} : {
  name: "axzllo-FullScreenElement",
  styles: "height:1.425rem;min-height:1.425rem;width:1.425rem;min-width:1.425rem;border-width:0px;background-image:none;label:FullScreenElement;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const FullScreenElement = ({
  disabled: isElementDisabled
}: {
  disabled?: boolean;
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const isFullScreen = useStreamStore(state => state.isFullScreen);
  useEffect(() => {
    const isApiPresent = isFullScreenApiAvailable();
    setIsDisabled(!isApiPresent);
    if (!isApiPresent) {
      setIsFullScreen(false);
    }
  }, [isFullScreen]);
  if (isDisabled) {
    return _jsx(_Fragment, {});
  }
  return _jsxs("button", {
    onClick: () => setIsFullScreen(!isFullScreen),
    disabled: isElementDisabled,
    "aria-disabled": isElementDisabled,
    className: `btn control-tooltip-parent player_fullscreen_toggle ` + (isFullScreen ? 'player_max' : 'player_min'),
    children: [_jsx("span", {
      className: "control-tooltip",
      style: {
        left: '-310%'
      },
      children: isFullScreen ? 'Exit Full Screen' : 'Enter Full Screen'
    }), _jsx("img", {
      style: {
        opacity: isElementDisabled ? 0.5 : 1
      },
      src: "/newPlayer/fullscreen.svg",
      css: _ref,
      alt: "fullscreen"
    })]
  });
};
export default FullScreenElement;