import { useTrickPlayStore } from '@components/TrickPlay';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { useCountMetric } from '@components/VideoPlayer/Utils/helper';
import { useAdsStore } from '@stores/adsStore';
import { useStreamStore } from '@stores/streamStore';
import { increaseCount, updateMetric } from '@stores/usePlayerEvents';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import * as workerTimers from 'worker-timers';
import WatchStreamInterval from './WatchStreamInterval';

/**
 * @description Used to maintain count into zustand storage even when user moved to a different page.
 *          Need to reset / set count to 0 when not in use, otherwise can cause additional count calculation.
 * @returns {undefined}
 */
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const useStartCount = (name: any, cond?: () => boolean) => {
  const IS_COUNT_METRIC = useCountMetric();
  const condRef = useRef<(() => boolean) | undefined>();
  const countMetricRef = useRef(false);
  countMetricRef.current = IS_COUNT_METRIC;
  condRef.current = cond;
  useEffect(() => {
    if (IS_COUNT_METRIC && name) {
      const intervalRef = workerTimers.setInterval(() => {
        if (countMetricRef.current === true && (typeof condRef.current !== 'function' || typeof condRef.current === 'function' && condRef.current() === true)) {
          const stream = usePlayerStore.getState().playerProps.stream;
          increaseCount(stream?.uid || '', name, 0.25); // increase by 0.5
        }
      }, 250);
      return () => {
        workerTimers.clearInterval(intervalRef);
      };
    }
  }, [IS_COUNT_METRIC, name]);
  return null;
};
const PlayerEventsComp = () => {
  const isTrickPlayVisible = useTrickPlayStore(state => !!(state.isVisible && state.thumbnailPrefix));
  const isPlaying = useStreamStore(state => state.isPlaying);
  const isBuffering = useStreamStore(state => state.isBuffering);
  const theatreMode = useStreamStore(state => state.theatreMode);
  const isFullScreen = useStreamStore(state => state.isFullScreen);
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);
  const isMuted = usePlayerStore(state => state.isMuted);
  const ad = useAdsStore(state => state.showInStreamAdControl);
  const streamUid = usePlayerStore(state => state.playerProps.stream?.uid || '');
  const streamCreatedAt = useStreamStore(state => state.stream?.created_at || 0);
  useStartCount('watch_duration_without_seekbar_used', () => {
    return !!(streamUid && isPlaying && !ad && !isBuffering);
  });
  useStartCount('total_watch_duration', () => {
    return !!(streamUid && isPlaying && !ad && !isBuffering);
  });
  useStartCount('buffered_time_in_seconds', () => {
    return !!(streamUid && isBuffering);
  });
  useStartCount('mute_duration', () => {
    return !!(streamUid && isPlaying && !ad && isMuted && !isBuffering);
  });
  useStartCount('pip_mode_watch_duration', () => {
    return !!(streamUid && isPortalOpen && isPlaying && !ad && !isBuffering);
  });
  useStartCount('theatre_mode_watch_duration', () => {
    return !!(streamUid && theatreMode && !isFullScreen && isPlaying && !ad && !isBuffering);
  });
  useStartCount('trick_play_mode_duration', () => {
    return !!(streamUid && isTrickPlayVisible);
  });
  useStartCount('fullscreen_mode_watch_duration', () => {
    return !!(streamUid && isFullScreen && isPlaying && !ad && !isBuffering);
  });
  useEffect(() => {
    if (!isTrickPlayVisible) return;
    const stream = usePlayerStore.getState().playerProps.stream;
    increaseCount(stream?.uid || '', 'trick_play_mode_count', 1);
  }, [isTrickPlayVisible]);
  useEffect(() => {
    if (!theatreMode) return;
    const stream = usePlayerStore.getState().playerProps.stream;
    increaseCount(stream?.uid || '', 'theatre_mode_toggle_count', 1);
  }, [theatreMode]);
  useEffect(() => {
    if (!isFullScreen) return;
    const stream = usePlayerStore.getState().playerProps.stream;
    increaseCount(stream?.uid || '', 'fullscreen_mode_toggle_count', 1);
  }, [isFullScreen]);
  useEffect(() => {
    if (!isPortalOpen) return;
    const stream = usePlayerStore.getState().playerProps.stream;
    increaseCount(stream?.uid || '', 'pip_mode_toggle_count', 1);
  }, [isPortalOpen]);
  useEffect(() => {
    if (!isBuffering) return;
    const stream = usePlayerStore.getState().playerProps.stream;
    increaseCount(stream?.uid || '', 'times_buffered', 1);
  }, [isBuffering]);
  useEffect(() => {
    if (streamUid && streamCreatedAt > 0) {
      const now = dayjs();
      const streamUploadTime = streamCreatedAt; // Specify the stream upload time using Day.js
      const millisecondsDiff = now.diff(streamUploadTime);
      const secondsDiff = Math.floor(millisecondsDiff / 1000);
      updateMetric(streamUid, 'stream_join_time_in_epoch', now.unix());
      updateMetric(streamUid, 'stream_join_time', secondsDiff);
    } else if (!streamUid) {
      // reset to 0 if no stream is present
      updateMetric(streamUid || '', 'stream_join_time_in_epoch', 0);
      updateMetric(streamUid || '', 'stream_join_time', 0);
    }
  }, [streamUid, streamCreatedAt]);
  return _jsx(_Fragment, {
    children: _jsx(WatchStreamInterval, {
      streamUid: streamUid
    })
  });
};
export default PlayerEventsComp;