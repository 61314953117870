function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1fpd1ja",
  styles: "margin:0px;margin-right:0.5rem;height:0.5rem;width:0.5rem;@media (min-width: 768px){height:0.75rem;width:0.75rem;}"
} : {
  name: "khpecn-MenuItem",
  styles: "margin:0px;margin-right:0.5rem;height:0.5rem;width:0.5rem;@media (min-width: 768px){height:0.75rem;width:0.75rem;};label:MenuItem;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "u7ytkp",
  styles: "margin-right:0.5rem"
} : {
  name: "y4d6s5-MenuItem",
  styles: "margin-right:0.5rem;label:MenuItem;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "f89un2",
  styles: "margin-left:0.25rem;height:0.5rem;width:0.5rem;--tw-rotate:180deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));@media (min-width: 768px){margin-left:0.5rem;height:0.75rem;width:0.75rem;}"
} : {
  name: "64y1bk-MenuItem",
  styles: "margin-left:0.25rem;height:0.5rem;width:0.5rem;--tw-rotate:180deg;transform:translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));@media (min-width: 768px){margin-left:0.5rem;height:0.75rem;width:0.75rem;};label:MenuItem;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const MenuItem = ({
  title,
  icon,
  value,
  hideArrow,
  showBackArrow,
  className,
  onClick,
  isSubMenu
}: {
  title: React.ReactNode;
  onClick?: () => void;
  icon?: React.ReactNode;
  value?: React.ReactNode;
  className?: string;
  hideArrow?: boolean;
  showBackArrow?: boolean;
  isSubMenu?: boolean;
}) => {
  return _jsx(React.Fragment, {
    children: _jsxs("button", {
      className: ['setting-menu-item', className || '', isSubMenu ? 'sub-menu-item' : ''].join(' '),
      onClick: onClick,
      children: [_jsxs("span", {
        className: "setting-item-label",
        children: [showBackArrow ? _jsx("img", {
          src: "/leaderboard/leftArrow.svg",
          alt: "right-arrow",
          css: _ref
        }) : null, icon ? _jsx("span", {
          css: _ref2,
          children: icon
        }) : null, title]
      }, "title-component"), value || !hideArrow ? _jsxs("div", {
        className: "setting-item-value",
        children: [value || null, hideArrow ? null : _jsx("img", {
          src: "/leaderboard/leftArrow.svg",
          alt: "right-arrow",
          css: _ref3
        })]
      }, "value-component") : null]
    })
  });
};
export default MenuItem;