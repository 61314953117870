const getStreamerMapping = require('./streamerMapping') // only fetch data in server, client is []
// const LOCAL_MAPPING = {}

const convertStreamerIdIntoUsername = (streamerId) => {
  const STREAMERS = getStreamerMapping()
  // if (LOCAL_MAPPING[streamerId]) {
  //   return LOCAL_MAPPING[streamerId]
  // }
  for (let streamer of STREAMERS) {
    if (streamerId == streamer.streamer_id) {
      // LOCAL_MAPPING[streamerId] = streamer.streamer_username.toLowerCase()
      // return LOCAL_MAPPING[streamerId]
      return streamer.streamer_username.toLowerCase()
    }
  }
  return streamerId
}

module.exports = {
  converStreamerIdIntoUsername: convertStreamerIdIntoUsername,
}
