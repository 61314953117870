function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import usePlayerContext from '@components/VideoPlayer/store/WithContextProvider';
import { formatTime } from '@components/VideoPlayer/Utils/utils';
import { useStreamStore } from '@stores/streamStore';
import { increaseCount } from '@stores/usePlayerEvents';
import React from 'react';
import PlayerSettingComp from '../PlayerSettingComp';
import BackToLive from './BackToLive';
import FullScreenElement from './FullScreenElement';
import MiniPlayerToogle from './MiniPlayerToggle';
import PlayPauseToggle from './PlayPauseToggle';
import SeekbarControls from './SeekbarControls';
import TheatreMode from './TheatreMode';
import VolumeControl from './VolumeControlComp';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsPlaying
} = useStreamStore.getState();
const {
  setVolume,
  setIsMuted
} = usePlayerStore.getState();
const {
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "18icuxn",
  styles: "--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity))"
} : {
  name: "1b6pwmz-PlayerControls",
  styles: "--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));label:PlayerControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "qqykjf",
  styles: "margin-right:0.25rem"
} : {
  name: "1rmkpnh-PlayerControls",
  styles: "margin-right:0.25rem;label:PlayerControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1y2mh23",
  styles: "margin-left:0.25rem"
} : {
  name: "2t7v3o-PlayerControls",
  styles: "margin-left:0.25rem;label:PlayerControls;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const PlayerControls = () => {
  const {
    onSeekToThisPosition
  } = usePlayerContext();
  const isPlaying = useStreamStore(state => state.isPlaying);
  const currentVolume = usePlayerStore(state => state.volume);
  const isPlayerMuted = usePlayerStore(state => state.isMuted);
  const playerDuration = usePlayerStore(state => state.playerDuration);
  const playerPosition = usePlayerStore(state => state.playerPosition);
  const stream = usePlayerStore(state => state.playerProps.stream);
  const isChannelLive = stream?.status === 20;
  return _jsxs(React.Fragment, {
    children: [_jsxs("div", {
      className: "player-controls-wrapper player-setting-wrapper",
      children: [_jsxs("div", {
        className: "player-controls-btn-container player-main-control",
        children: [_jsx(PlayPauseToggle, {
          onClick: () => {
            increaseCount(stream?.uid || '', 'player_play_pause_toggle_count', 1);
            setIsPlaying(!isPlaying);
          },
          isPaused: !isPlaying,
          isDisabled: false,
          isPortalOpen: false
        }), isChannelLive && _jsx(BackToLive, {
          onClickLive: () => {
            const playerDuration = usePlayerStore.getState().playerDuration;
            onSeekToThisPosition({
              position: playerDuration,
              duration: playerDuration,
              progress: 100
            });
          }
        }), _jsxs("div", {
          css: _ref,
          children: [_jsx("p", {
            className: "time-since-live player-position",
            css: _ref2 // ref={timeSinceLiveRef}
            // style={Object.assign(
            //   !hasError && isVodAvailable
            //     ? {}
            //     : { opacity: 0, display: 'none' }
            // )}
            ,
            children: formatTime(playerPosition)
          }), isChannelLive ? _jsx(_Fragment, {}) : _jsxs(_Fragment, {
            children: ["/", _jsx("p", {
              className: "time-since-live player-duration",
              css: _ref3,
              children: formatTime(playerDuration)
            })]
          })]
        }), _jsx(VolumeControl, {
          currentVolume: isPlayerMuted ? 0 : currentVolume,
          onToggle: () => {
            increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            setIsMuted(!isPlayerMuted);
          },
          onChange: (n_volume: number) => {
            const p_muted = usePlayerStore.getState().isMuted;
            if (p_muted && n_volume > 0) {
              increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            } else if (!p_muted && n_volume === 0) {
              increaseCount(stream?.uid || '', 'mute_toggle_count', 1);
            }
            setVolume(n_volume);
          },
          isMuted: isPlayerMuted
        })]
      }), _jsxs("div", {
        className: "player-controls-btn-container player-setting",
        onPointerDown: () => resetControlSectionVisiblity(),
        children: [_jsx(PlayerSettingComp, {}), _jsx(MiniPlayerToogle, {}), _jsx(TheatreMode, {}), _jsx(FullScreenElement, {})]
      })]
    }), _jsx(SeekbarControls, {})]
  });
};
export default PlayerControls;