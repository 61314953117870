import { meApi, registerDeviceProfile } from '@functions/api';
import { getProfileInfo, getWalletDetails } from '@functions/profile';
import { Amplitude } from '@modules/analytics';
import { removeLoginInfo } from '@modules/storage/loginInformation';
import { useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { useProfileStore } from '@stores/profileStore';
import { getCookie } from '@utils/cookies';
import * as React from 'react';
export default function useHandleLogin() {
  const setMe = useAuthStore(state => state.setMe);
  const setAccessToken = useAuthStore(state => state.setAccessToken);
  const setRefreshToken = useAuthStore(state => state.setRefreshToken);
  const user_uid = useAuthStore(state => state.me?.user_uid);
  const updateMe = useAuthStore(state => state.updateMe);
  const setWalletLoading = useProfileStore(state => state.setWalletLoading);
  const setWallet = useProfileStore(state => state.setWallet);
  const accessToken = useAuthStore(state => state.accessToken);
  const setInitialApiLoadingState = useAppStore(state => state.setInitialApiLoadingState);
  const postLoginCallback = React.useCallback(() => {
    async function fetchWalletDetails(): Promise<void> {
      setWalletLoading(true);
      const res = await getWalletDetails();
      if (res.self_wallet) {
        setInitialApiLoadingState(false);
        setWallet({
          gold: res.self_wallet.gold,
          diamond: res.self_wallet.diamond
        });
      }
      setWalletLoading(false);
    }
    async function _getProfileInfo(): Promise<void> {
      const res = await getProfileInfo();
      if (res) {
        delete res.username;
        updateMe(res);
        setInitialApiLoadingState(false);
      }
      if (res.error_code === 'F001') {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        window.location.reload();
      }
    }
    fetchWalletDetails();
    _getProfileInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    async function handleAuth(): Promise<void> {
      setInitialApiLoadingState(true);
      const mode = getCookie('mode');
      if (mode === 'logged-in') {
        const result = await meApi();
        // Force logout event if meApi fails
        if (result.statusCode && result.statusCode !== 200 || result.status_code && result.status_code !== 200) {
          Amplitude.logEvent('logout', {
            forced: true,
            api_failed: '/profile/me/',
            via_refresh_token: false,
            stack: result,
            reason: result?.message ?? 'Unknown Error'
          });
        }
        if (result?.username) {
          setMe(result);
          if (getCookie('access_token')) {
            setAccessToken((getCookie('access_token') as string));
          }
          if (getCookie('refresh_token')) {
            setRefreshToken((getCookie('refresh_token') as string));
          }
        } else if (result?.data?.username) {
          setMe(result?.data);
          if (getCookie('access_token')) {
            setAccessToken((getCookie('access_token') as string));
          }
          if (getCookie('refresh_token')) {
            setRefreshToken((getCookie('refresh_token') as string));
          }
        } else {
          removeLoginInfo();
          const result = await registerDeviceProfile();
          setInitialApiLoadingState(false);
          if (result?.access_token && result?.refresh_token) {
            setAccessToken(result.access_token);
            setRefreshToken(result.refresh_token);
          }
        }
      } else {
        removeLoginInfo();
        const result = await registerDeviceProfile();
        setInitialApiLoadingState(false);
        if (result?.access_token && result?.refresh_token) {
          setAccessToken(result.access_token);
          setRefreshToken(result.refresh_token);
        }
      }
    }
    handleAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (user_uid && accessToken) {
      postLoginCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_uid, accessToken]);
  return null;
}